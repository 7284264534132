import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '@praxis/component-auth'
import { useAnalytics } from '@praxis/component-analytics'
import linkConfig from '../../config/linkConfig'
import { checkUser } from '../../utills/Common'
import {
  postMarkAsDone,
  resetMarkAsDone,
} from './../../store/Home/actionCreator'
import { Notification } from '../../store/Notification/actionCreator'

export const useHome = (props) => {
  const dispatch = useDispatch()
  const auth = useAuth()
  const { session } = auth
  const history = useNavigate()
  const user = checkUser(session)
  let { supplier_id, id } = useParams()
  const { trackClick } = useAnalytics()
  const vmmUrl = `${linkConfig.vmm.site}${linkConfig.vmm.redirect}`

  const supplierData = useSelector((state) => {
    const { supplierInfo } = state
    const { info } = supplierInfo
    return info
  }, [])

  const supplierTargetSetup = useSelector((state) => {
    const { supplier } = state
    return supplier?.targetSetUp
  }, [])
  const supplierInternalData = useSelector((state) => {
    const { supplierInfo } = state
    return supplierInfo.internalData
  }, [])

  const supplierAlertData = useSelector((state) => {
    const { supplierInfo } = state
    const { alert } = supplierInfo
    return alert
  }, [])

  const supplierTodos = useSelector((state) => {
    const { supplierInfo } = state
    const { supplierTodos } = supplierInfo
    return supplierTodos
  }, [])

  const markAsDoneData = useSelector((state) => {
    const { supplierInfo } = state
    const { markAsDone } = supplierInfo
    return markAsDone
  }, [])

  const pendingAction = useSelector((state) => {
    const { supplierInfo } = state
    const { todoOverview } = supplierInfo
    const { data } = todoOverview
    return data
  })

  const supplierTypesInfo = useSelector((state) => {
    const { supplierInfo } = state
    const { info } = supplierInfo
    const { presupplierInfo } = info
    return presupplierInfo?.[0]?.supplier_types
  })

  const allSupplierLevelTodos = pendingAction?.Supplier_ToDo
  const contactsRole = {
    contacts_role:
      supplierAlertData?.supplierAlert?.alerts?.contacts_role &&
      supplierAlertData?.supplierAlert?.alerts?.contact_validation_pending,
  }

  useEffect(() => {
    if (supplierData?.error && user?.isUser) {
      const { error } = supplierData
      const { supplierInfo } = supplierData
      const { supplier_id } = supplierInfo
      if (error?.status === 401 || !supplier_id) {
        handleUnAuth()
      }
    }
    /*
    if (!user?.isUser) {
      if (user?.memberOf.indexOf(internal) === -1) {
        handleUnAuth()
      }
    }
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierData])

  const handleUnAuth = () => {
    history('/unauthorized')
    return
  }
  const navigateTo = (event, url, title = '') => {
    const { supplierInfo } = supplierData
    const { supplier_id } = supplierInfo
    if (url && event) {
      event && event.preventDefault()
      const { target } = event
      trackClick(target.textContent)
      if (/^https?:\/\//i.test(url)) {
        if (url.includes('mvs')) {
          if (title !== '') {
            url += `&manufacturingTypeId=${title}`
            url += `&supplierId=${supplier_id}`
          }
          // if MVS open in same tab
          window.location.href = url
        } else {
          window.open(url, '_blank')
        }
      } else {
        url = makePath(url)
        history(url.pathname ? url.pathname : url, { state: url.state })
      }
    } else {
      if (url) {
        url = makePath(url)
        history(url.pathname ? url.pathname : url, { state: url.state })
      }
    }
  }
  const makePath = (url) => {
    resetMarkDone()
    supplier_id = !supplier_id ? supplierInternalData?.id : supplier_id
    if (url?.state?.tab === 'relationships') {
      supplier_id = supplierInternalData?.id
    }
    if (!user?.isUser) {
      if (supplier_id) {
        if (typeof url === 'object') {
          let { pathname } = url
          let path
          if (pathname?.indexOf(supplier_id) === -1) {
            pathname?.includes('todo')
              ? (path = `${pathname}/${supplier_id}`)
              : (path =
                  pathname === '/'
                    ? `/supplier-view/${supplier_id}`
                    : `${pathname}${supplier_id}`)

            url = { ...url, pathname: path }
          }
        } else {
          if (!url.includes('/contact') && !url.includes('/brand')) {
            url =
              url.indexOf(supplier_id) === -1 ? `${url}/${supplier_id}` : url
          }
        }
        return url
      } else {
        history('/')
      }
    } else {
      return url
    }
  }
  const markDone = (type, value = true) => {
    let obj, supplierId
    if (Array.isArray(type)) {
      obj = type.map((val) => ({
        [val]: value,
      }))
    } else {
      switch (type) {
        case 'company_basic_info_confirmed':
        case 'obs_expectation':
          obj = { [type]: 'CONFIRMED' }
          break
        case 'pol_agreement_signed':
        case 'supply_chain_cert_completed':
        case 'brands_completed':
        case 'coi_uploaded':
          obj = { [type]: value }
          break
        case 'all_doc':
          obj = {
            pol_agreement_signed: value,
            supply_chain_cert_completed: value,
            coi_uploaded: value,
          }
          break
        default:
          obj = ''
      }
    }
    if (id) {
      supplierId = id
    } else {
      supplierId = supplierData?.supplierInfo?.supplier_id
    }
    if (supplierId) {
      dispatch(postMarkAsDone(obj, supplierId))
    }
  }

  const checkTodoStatus = (supplier_type, todo) => {
    const supplierTypeTodos = pendingAction && pendingAction[supplier_type]
    const { supplierAlert } = supplierAlertData
    const { alerts } = supplierAlert ? supplierAlert : {}
    const allTodos = {
      ...supplierTypeTodos,
      ...allSupplierLevelTodos,
      ...contactsRole,
    }
    if (!(todo in allTodos)) {
      return undefined
    }

    if (
      todo === 'contacts_role' &&
      alerts?.supplier_type_status !== 'Under Review' &&
      alerts?.supplier_type_status !== 'Approved'
    ) {
      return allTodos && !allTodos[todo]
    } else if (todo === 'contacts_role') return undefined
    return allTodos && allTodos[todo]
  }

  useEffect(() => {
    const { data } = markAsDoneData
    if (data?.result === 'Success') {
      const type = data?.update_type
      let obj
      let bool = true
      switch (type) {
        case 'company_basic_info_confirmed':
        case 'obs_expectation':
          obj = 'basic_information'
          break
        case 'pol_agreement_signed':
        case 'supply_chain_cert_completed':
        case 'brands_completed':
          obj = 'brand'
          bool = false
          break
        case 'coi_uploaded':
          obj = 'contact'
          break
        case 'basic_company_information':
        case 'target_history':
        case 'supplier_manufacturing_information':
          bool = false
          break
        default:
          obj = 'basic_information'
      }
      if (bool) {
        const tabPath = { state: { tab: obj } }
        navigateTo('', tabPath)
      }
      dispatch(Notification(true, 'Updated successfully.'))

      resetMarkDone()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, markAsDoneData])

  // todos pending should come on alert if the todo is pending and falls in a status that comes before the current status
  // if a todo is pending in a status that hasn't been reached yet, then it should not come up in alert..
  const checkTodoCompleted = (todoName) => {
    const allSupplierTypesTodos =
      supplierTodos?.data?.supplier_types_todos || []
    for (const supplierType of allSupplierTypesTodos) {
      for (const status of supplierType.supplier_type_statuses_todos) {
        const todo = status.todos.find((t) => t.name === todoName)
        if (todo && !todo.completed) {
          return false
        }
        // only check till the current status
        if (status.progression_status === 'in_progress') {
          break
        }
      }
    }
    return true
  }

  // if todos alert is true, that means we need to display an alert with a red dot
  const checkTodosAlert = (applicableTodos) => {
    let allTodosDone = true
    applicableTodos?.forEach((todo) => {
      allTodosDone = allTodosDone & checkTodoCompleted(todo)
      if (!allTodosDone) return
    })
    return !allTodosDone
  }

  const createLink = (link, params) => {
    let obj
    const linkName = typeof link === 'object' ? link.pathname : link
    if (linkName?.indexOf('/location') !== -1) {
      obj = `${linkName}${params}`
    } else if (!linkName.includes('/')) {
      const vmLink = `${vmmUrl}${params}&page=${linkName}`
      obj = vmLink.replace('&sid=', '')
    } else {
      obj = linkName
    }
    if (typeof link === 'object') {
      obj = { ...link, pathname: obj }
    }
    return obj
  }
  const resetMarkDone = () => {
    resetMarkAsDone(dispatch)
  }

  const hasAllSubTypesCompleted = () => {
    let allDone = true

    supplierTypesInfo?.forEach((supplierType) => {
      if (supplierType.id === 8) {
        if (
          !(
            supplierType?.supplier_sub_types &&
            supplierType.supplier_sub_types?.every(
              (subtype) =>
                subtype.supplier_sub_type_status.value === 'Completed' ||
                subtype.supplier_sub_type_status.value === 'Vetting Completed'
            )
          )
        ) {
          allDone = false
          return allDone
        }
      }
    })
    return allDone
  }

  return {
    navigateTo,
    markDone,
    createLink,
    resetMarkDone,
    checkTodoStatus,
    markAsDoneData,
    supplierAlertData,
    pendingAction,
    supplierTodos,
    supplierData,
    user,
    checkTodosAlert,
    supplierTargetSetup,
    hasAllSubTypesCompleted,
  }
}
