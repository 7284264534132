import * as ACTIONS from './actionType'
import { dayjs } from '../../components/common/Date/Dayjs'
import { filterConstants } from '../../pages/Brand/constants/brandConstants'

export const InternalInfoState = {
  profileInfo: {
    data: [],
    loading: false,
    error: false,
  },
  locationInfo: {
    data: [],
    loading: false,
    error: null,
  },
  // locationReview: {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },
  contactInfo: {
    data: [],
    loading: false,
    error: false,
  },
  contactReviewInfo: {
    data: [],
    loading: false,
    error: false,
  },
  invite: {
    data: [],
    loading: false,
    error: false,
  },
  postInvite: {
    postData: [],
    loading: false,
    error: false,
  },
  inviteInfo: {
    data: [],
    loading: false,
    error: false,
  },
  allInviteInfo: {
    data: [],
    loading: null,
    error: false,
  },
  allSupplierList: {
    data: [],
    loading: false,
    error: null,
  },
  locationSearchOptions: {
    data: null,
    loading: null,
    error: false,
    reset: true,
  },
  editDunsList: {
    data: [],
    loading: false,
    error: false,
  },
  brandsInfo: {
    data: [],
    loading: null,
    error: false,
  },
  brandFilter: {
    brandId: '',
    brandName: '',
    brandType: null,
    brandClassification: null,
    brandStatus: null,
    brandPageSize: filterConstants.defaultPageSize,
    brandPageNumber: filterConstants.intialPage,
  },
}
export default function internal(state = InternalInfoState, action = {}) {
  let departmentsList
  let departmentsListCount
  let supplierTypesData
  let supplierTypesDataCount
  let supplierSubTypesData
  let supplierSubTypesCount
  let matricesList
  let matricesListCount
  let productsList
  let productsListCount

  switch (action.type) {
    case ACTIONS.TYPE.SUPPLIER_PROFILE_INIT: {
      return {
        ...state,
        ...state.profileInfo,
        profileInfo: {
          loading: true,
          error: false,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_PROFILE_INFO: {
      return {
        ...state,
        ...state.profileInfo,
        profileInfo: {
          data: action.data,
          loading: false,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_PROFILE_FAIL: {
      return {
        ...state,
        ...state.profileInfo,
        profileInfo: {
          loading: false,
          error: action.error,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_LOCATION_INIT: {
      return {
        ...state,
        ...state.locationInfo,
        locationInfo: {
          loading: true,
          error: null,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_LOCATION_INFO: {
      const data = action.data.map((loc) => {
        return {
          ...loc,
          // c_date: loc?.base_vo?.created_ts,
          c_date: dayjs(loc?.base_vo?.created_ts).format(),
        }
      })
      const sortData = data.sort((a, b) => {
        const date1 = dayjs(a.c_date).valueOf()
        const date2 = dayjs(b.c_date).valueOf()
        return date2 > date1 ? 1 : date1 > date2 ? -1 : 0
      })
      return {
        ...state,
        ...state.locationInfo,
        locationInfo: {
          data: sortData,
          loading: false,
          error: null,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_LOCATION_FAIL: {
      return {
        ...state,
        ...state.locationInfo,
        locationInfo: {
          loading: false,
          error: action.error,
          data: [],
        },
      }
    }
    case ACTIONS.TYPE.EDIT_DUNS_INIT: {
      return {
        ...state,
        ...state.editDunsList,
        editDunsList: {
          loading: true,
          error: null,
        },
      }
    }
    case ACTIONS.TYPE.EDIT_DUNS_INFO: {
      return {
        ...state,
        ...state.editDunsList,
        editDunsList: {
          data: action.data,
          loading: false,
          error: null,
        },
      }
    }
    case ACTIONS.TYPE.EDIT_DUNS_FAIL: {
      return {
        ...state,
        ...state.editDunsList,
        editDunsList: {
          loading: false,
          error: action.error,
          data: [],
        },
      }
    }
    // case ACTIONS.TYPE.REVIEW_LOCATION_INIT: {
    //   return {
    //     ...state,
    //     ...state.locationReview,
    //     locationReview: {
    //       loading: true,
    //       error: false,
    //     },
    //   }
    // }
    // case ACTIONS.TYPE.REVIEW_LOCATION_INFO: {
    //   return {
    //     ...state,
    //     ...state.locationReview,
    //     locationReview: {
    //       data: action.data,
    //       loading: false,
    //       error: false,
    //     },
    //   }
    // }
    // case ACTIONS.TYPE.REVIEW_LOCATION_FAIL: {
    //   return {
    //     ...state,
    //     ...state.locationReview,
    //     locationReview: {
    //       loading: false,
    //       error: action.error,
    //     },
    //   }
    // }
    case ACTIONS.TYPE.SUPPLIER_CONTACT_INIT: {
      return {
        ...state,
        ...state.contactInfo,
        contactInfo: {
          loading: true,
          error: false,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_CONTACT_INFO: {
      return {
        ...state,
        ...state.contactInfo,
        contactInfo: {
          data: action.data,
          loading: false,
          error: false,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_CONTACT_FAIL: {
      return {
        ...state,
        ...state.contactInfo,
        contactInfo: {
          loading: false,
          error: action.error,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_CONTACT_REVIEW_INIT: {
      return {
        ...state,
        ...state.contactReviewInfo,
        contactReviewInfo: {
          loading: true,
          error: false,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_CONTACT_REVIEW_FAIL: {
      return {
        ...state,
        ...state.contactReviewInfo,
        contactReviewInfo: {
          loading: false,
          error: action.error,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_CONTACT_DELETE: {
      return {
        ...state,
        ...state.contactInfo,
        contactInfo: {
          data: action.data,
          loading: false,
          error: false,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_CONTACT_REVIEW_INFO: {
      return {
        ...state,
        ...state.contactReviewInfo,
        contactReviewInfo: {
          data: action.data,
          loading: false,
          error: false,
        },
      }
    }
    /***  Invite start***/
    case ACTIONS.TYPE.SUPPLIER_INVITE_INIT: {
      return {
        ...state,
        ...state.invite,
        invite: {
          loading: true,
          error: false,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_INVITE_INFO: {
      departmentsList = action.departmentsData
      departmentsListCount = action.departmentsData.length
      supplierTypesData = action.supplierTypesData
      supplierTypesDataCount = action.supplierTypesData.length
      supplierSubTypesData = action.supplierSubTypesData
      supplierSubTypesCount = action.supplierSubTypesData.length
      matricesList = action.matricesData
      matricesListCount = action.matricesData.length
      productsList = action.productsData
      productsListCount = action.productsData.length
      const sorObj = departmentsList.sort((a, b) => a.dept_id - b.dept_id)
      departmentsList = sorObj?.filter(function (el) {
        return el.dept_name != null
      })
      const data = {
        departmentsList: departmentsList,
        departmentsListCount: departmentsListCount,
        supplierTypesData: supplierTypesData,
        supplierTypesDataCount: supplierTypesDataCount,
        supplierSubTypesData: supplierSubTypesData,
        supplierSubTypesCount: supplierSubTypesCount,
        matricesList: matricesList,
        matricesListCount: matricesListCount,
        productsList: productsList,
        productsListCount: productsListCount,
      }
      return {
        ...state,
        ...state.invite,
        invite: {
          data: data,
          loading: false,
          error: '',
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_INVITE_FAIL: {
      return {
        ...state,
        ...state.invite,
        invite: {
          loading: false,
          error: action.error,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_POST_INVITE_INIT: {
      return {
        ...state,
        ...state.postInvite,
        postInvite: {
          loading: true,
          error: false,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_POST_INVITE_INFO: {
      return {
        ...state,
        ...state.postInvite,
        postInvite: {
          postData: action.data,
          loading: false,
          error: false,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_POST_INVITE_FAIL: {
      return {
        ...state,
        ...state.postInvite,
        postInvite: {
          loading: false,
          error: action.error,
        },
      }
    }

    case ACTIONS.TYPE.LOCATION_SEARCH_OPTIONS_INFO: {
      return {
        ...state,
        locationSearchOptions: {
          data: action.data,
          loading: false,
          error: action.error,
          reset: false,
        },
      }
    }
    case ACTIONS.TYPE.LOCATION_SEARCH_OPTIONS_RESET: {
      return {
        ...state,
        ...state.locationSearchOptions,
        locationSearchOptions: {
          loading: false,
          error: action.error,
          reset: true,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_USER_INVITE_INIT: {
      return {
        ...state,
        ...state.inviteInfo,
        inviteInfo: {
          data: [],
          loading: true,
          error: false,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_USER_INVITE_INFO: {
      return {
        ...state,
        ...state.inviteInfo,
        inviteInfo: {
          // data: [action.data],
          data: action.data,
          loading: false,
          error: false,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_USER_INVITE_FAIL: {
      return {
        ...state,
        ...state.inviteInfo,
        inviteInfo: {
          loading: false,
          error: action.error,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_INVITE_LIST_INIT: {
      return {
        ...state,
        ...state.allInviteInfo,
        allInviteInfo: {
          loading: true,
          error: false,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_INVITE_LIST_INFO: {
      return {
        ...state,
        ...state.allInviteInfo,
        allInviteInfo: {
          data: action.data,
          loading: false,
          error: false,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_INVITE_LIST_FAIL: {
      return {
        ...state,
        ...state.allInviteInfo,
        allInviteInfo: {
          data: [],
          loading: false,
          error: action.error,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_INVITE_LIST_RESET: {
      return {
        ...state,
        ...state.allInviteInfo,
        allInviteInfo: {
          data: [],
          loading: null,
          error: false,
        },
      }
    }
    /***  Invite Ends***/
    /*** Supplier List ***/
    case ACTIONS.TYPE.SUPPLIER_LIST_INIT: {
      return {
        ...state,
        ...state.allSupplierList,
        allSupplierList: {
          loading: true,
          error: null,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_LIST_INFO: {
      return {
        ...state,
        ...state.allSupplierList,
        allSupplierList: {
          data: action.data,
          loading: false,
          error: null,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_LIST_FAIL: {
      return {
        ...state,
        ...state.allSupplierList,
        allSupplierList: {
          loading: false,
          error: action.error,
          data: [],
        },
      }
    }
    /** Brand List */
    case ACTIONS.TYPE.BRAND_LIST_INIT: {
      return {
        ...state,
        ...state.brandsInfo,
        brandsInfo: {
          loading: true,
          error: false,
        },
      }
    }
    case ACTIONS.TYPE.BRAND_LIST_INFO: {
      return {
        ...state,
        ...state.brandsInfo,
        brandsInfo: {
          data: action.data,
          loading: false,
          error: false,
        },
      }
    }
    case ACTIONS.TYPE.BRAND_LIST_FAIL: {
      return {
        ...state,
        ...state.brandsInfo,
        brandsInfo: {
          data: [],
          loading: false,
          error: action.error,
        },
      }
    }
    case ACTIONS.TYPE.BRAND_ID_FILTER: {
      return {
        ...state,
        brandFilter: {
          ...state.brandFilter,
          brandId: action.value,
        },
      }
    }
    case ACTIONS.TYPE.BRAND_NAME_FILTER: {
      return {
        ...state,
        brandFilter: {
          ...state.brandFilter,
          brandName: action.value,
        },
      }
    }
    case ACTIONS.TYPE.BRAND_TYPE_FILTER: {
      return {
        ...state,
        brandFilter: {
          ...state.brandFilter,
          brandType: action.value,
        },
      }
    }
    case ACTIONS.TYPE.BRAND_CLASSIFICATION_FILTER: {
      return {
        ...state,
        brandFilter: {
          ...state.brandFilter,
          brandClassification: action.value,
        },
      }
    }
    case ACTIONS.TYPE.BRAND_STATUS_FILTER: {
      return {
        ...state,
        brandFilter: {
          ...state.brandFilter,
          brandStatus: action.value,
        },
      }
    }
    case ACTIONS.TYPE.BRAND_PAGE_SIZE: {
      return {
        ...state,
        brandFilter: {
          ...state.brandFilter,
          brandPageSize: action.data,
        },
      }
    }

    case ACTIONS.TYPE.BRAND_PAGE_NUMBER: {
      return {
        ...state,
        brandFilter: {
          ...state.brandFilter,
          brandPageNumber: action.data,
        },
      }
    }

    case ACTIONS.TYPE.CLEAR_BRAND_FILTER: {
      return {
        ...state,
        brandFilter: {
          brandId: '',
          brandName: '',
          brandType: null,
          brandClassification: null,
          brandStatus: null,
          brandPageSize: filterConstants.defaultPageSize,
          brandPageNumber: filterConstants.intialPage,
        },
      }
    }
    /*** Brand Ends ***/
    default: {
      return state
    }
  }
}
