export const AD_GROUP =
  process.env.REACT_APP_ENV === 'prod'
    ? {
        VIEW: 'APP-SM-VIEW',
        UPDATE: 'APP-SM-UPDATE',
        APPROVE: 'APP-SM-APPROVE',
        SECURITY: 'APP-SM-SECURITY',
        SYSADMIN: 'APP-SM-SYSADMIN',
        PAYMENT: 'APP-SM-PAYMENT',
        PARTNERVIEW: 'APP-SM-PARTNERVIEW',
        PARTNERUPDATE: 'APP-SM-PARTNERUPDATE',
        PARTNERADMIN: 'APP-SM-PARTNERADMIN',
        PARTNERPAYMENT: 'APP-SM-PARTNERPAYMENT',
      }
    : {
        VIEW: 'APP-SM-VIEW-STAGE',
        UPDATE: 'APP-SM-UPDATE-STAGE',
        APPROVE: 'APP-SM-APPROVE-STAGE',
        SECURITY: 'APP-SM-SECURITY-STAGE',
        SYSADMIN: 'APP-SM-SYSADMIN-STAGE',
        PAYMENT: 'APP-SM-PAYMENT-STAGE',
        PARTNERVIEW: 'APP-SM-PARTNERVIEW',
        PARTNERUPDATE: 'APP-SM-PARTNERUPDATE',
        PARTNERADMIN: 'APP-SM-PARTNERADMIN',
        PARTNERPAYMENT: 'APP-SM-PARTNERPAYMENT',
      }

export const INTERNAL_AD_GROUP = [
  AD_GROUP.VIEW,
  AD_GROUP.UPDATE,
  AD_GROUP.APPROVE,
  AD_GROUP.SECURITY,
  AD_GROUP.SYSADMIN,
  AD_GROUP.PAYMENT,
]

export const EXTERNAL_AD_GROUP = [
  AD_GROUP.PARTNERVIEW,
  AD_GROUP.PARTNERUPDATE,
  AD_GROUP.PARTNERADMIN,
  AD_GROUP.PARTNERPAYMENT,
]

export const INTERNAL_AD_GROUPS_WITH_ADD = [
  'APP-SM-SYSADMIN',
  'APP-SM-APPROVE',
  'APP-SM-SYSADMIN-STAGE',
  'APP-SM-APPROVE-STAGE',
  'APP-SM-UPDATE',
  'APP-SM-UPDATE-STAGE',
]
