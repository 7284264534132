import { useAuth } from '@praxis/component-auth'

export const useAuthAccessCheck = (props) => {
  const authInfo = useAuth()

  const usersCurrentADGroup = authInfo?.session?.userInfo?.memberOf || []

  const adGroupNeeded = props?.allowedGroup || []

  const shouldAccessBeGiven = adGroupNeeded.some((adGroup) =>
    usersCurrentADGroup.includes(adGroup)
  )

  return {
    shouldAccessBeGiven,
  }
}
