import { useState } from 'react'
export const useChipComponent = (props) => {
  const [showAuditModal, setShowAuditModal] = useState(false)
  const [showAuditModalDiversity, setShowAuditModalDiversity] = useState(false)
  const address = (data) => {
    let address
    if (data) {
      address = Object.keys(data).map(function (k, index) {
        if (data[k] !== null) {
          return index + 1 !== Object.keys(data).length
            ? `${data[k]}, `
            : `${data[k]} `
        } else {
          return false
        }
      })
    }
    return address
  }
  // const addressProfile = (data) => {
  //   if (data) {
  //     let {
  //       address_line1,
  //       address_country,
  //       // address_country_code,
  //       address_line2,
  //       address_locality,
  //       // address_region,
  //       // continental_region,
  //       postal_code,
  //     } = data
  //     const addr = `${checkEmpty(address_line1)},${checkEmpty(
  //       address_line2
  //     )},${checkEmpty(address_locality)},${checkEmpty(
  //       postal_code
  //     )},${checkEmpty(address_country)}`

  //     return addr.replace(/,+/g, ', ')
  //   }
  // }

  const productCategories = (data, limit) => {
    let categories, obj
    if (data) {
      obj = limit ? data.slice(0, limit) : data
      categories = obj.map((item, index) => {
        const { description, code } = item
        const str = `${code} - ${description}`
        return `${str} `
        // return index + 1 !== Object.keys(obj).length ? `${str} ` : `${str}`
      })
    }
    return categories
  }
  const diversities = (data) => {
    let diversitiesData
    if (data) {
      diversitiesData = data.map((item, index) => {
        const { diversity_active, diversity_value } = item
        const str = `${diversity_value}`
        if (diversity_active) {
          return index + 1 !== Object.keys(data).length ? `${str} ` : `${str}`
        } else {
          return null
        }
      })
    }
    const filtered = diversitiesData?.filter(function (el) {
      return el != null
    })
    return filtered?.length > 0
      ? filtered
      : 'No metrics to display at this time'
  }
  const contactPerson = (contact) => {
    let contactUser
    if (contact) {
      const contactFilter = contact.filter((el) => el.most_senior_principal)
      if (contactFilter.length > 0) {
        const { full_name, job_titles } = contactFilter[0]
        contactUser = `${full_name} - ${job_titles[0]?.title}`
      }
    }
    return contactUser ? contactUser : []
  }

  const contactPersonInfo = (contact, num) => {
    let contactUser
    if (contact) {
      const contactFilter = contact.filter((el) => el.most_senior_principal)
      if (contactFilter.length > 0) {
        const { given_name, middle_name, family_name, job_titles } =
          contactFilter[0]

        if (num === 0) {
          contactUser = `${given_name}`
        } else if (num === 1) {
          contactUser = `${middle_name}`
        } else if (num === 2) {
          contactUser = `${family_name}`
        } else if (num === 3) {
          contactUser = `${job_titles ? job_titles[0]?.title : ''}`
        }
      }
    }
    return contactUser ? contactUser : []
  }
  const checkEmpty = (data) => {
    return data ? data : ''
  }
  const usCurrency = (amount, type) => {
    const cur = type ? type : 'USD'
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: cur,
    })
    if (!amount) {
      return ''
    }
    return formatter?.format(amount)
  }

  const handleModalVisibility = (isVisible) => {
    setShowAuditModal(isVisible)
  }

  const handleModalVisibilityDiversity = (isVisible) => {
    setShowAuditModalDiversity(isVisible)
  }

  return {
    address,
    // addressProfile,
    productCategories,
    diversities,
    contactPerson,
    contactPersonInfo,
    checkEmpty,
    usCurrency,
    handleModalVisibility,
    showAuditModal,
    showAuditModalDiversity,
    handleModalVisibilityDiversity,
  }
}
