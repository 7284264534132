import * as React from 'react'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { Notification } from '../../../store/Notification/actionCreator'

export const Notify = () => {
  const { show, msg, isError, redirectPath, heading, duration } = useSelector(
    (state) => state.notification
  )

  const makeToast = ToastProvider.useToaster()
  const dispatch = useDispatch()
  const type = isError ? 'error' : 'success'
  const history = useNavigate()
  if (redirectPath) {
    setTimeout(() => history(redirectPath), 0)
  }

  useEffect(() => {
    dispatch(Notification(false, '', isError))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    show &&
    makeToast({
      autoHideDuration: duration,
      type: type,
      onComplete: () => dispatch(Notification(false, '', isError)),
      heading: heading,
      message: msg,
      links: [],
    })
  )
}

export default React.memo(Notify)
