export const isPhoneNumber = (phone) => {
  // const pattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i // eslint-disable-line
  const pattern = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i // eslint-disable-line
  return pattern.test(phone)
}
export const scrollTo = (ref) => {
  setTimeout(() => {
    ref.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }, 1000)
}
export const handleFocusElem = (listItem) => {
  let timer1 = setTimeout(() => listItem.focus(), 0)
  scrollTo(listItem, true)
  return () => {
    clearTimeout(timer1)
  }
}

export const findObjIndex = (data, value, key) => {
  return data.findIndex((obj) => obj[key] === value[key])
}
