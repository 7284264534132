/* Actions */
import {
  SET_LOADING,
  SET_VISIBLE,
  SET_ERROR,
  CHANGE_CURRENT_PAGE,
  RESET_COMMON,
} from '../actionType'

/* Constants */
// import { PAGE_TYPE } from '../../constants/pageConstants'
import {
  LOADING_COMPONENT,
  VISIBLE_COMPONENT,
  PAGE_TYPE,
} from '../../../constants/common'

/* Helpers */
import { getObjWithFalseVal } from '../../../utills/getObjWithFalseVal'

/* Initial State */
export const initialState = {
  currentPage: PAGE_TYPE.ADD_LOCATION,
  loadingComponent: getObjWithFalseVal(LOADING_COMPONENT),
  visibleComponent: getObjWithFalseVal(VISIBLE_COMPONENT),
  error: {
    isPresent: false,
    failedActionRef: '',
  },
}

export const commonReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case SET_LOADING: {
      return {
        ...state,
        loadingComponent: {
          ...state.loadingComponent,
          ...payload,
        },
      }
    }

    case SET_VISIBLE: {
      return {
        ...state,
        visibleComponent: {
          ...state.visibleComponent,
          ...payload,
        },
      }
    }

    case SET_ERROR: {
      const { bool, failedActionRef } = payload

      return {
        ...state,
        error: {
          isPresent: bool,
          failedActionRef: failedActionRef || '',
        },
      }
    }

    case CHANGE_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: payload,
      }
    }

    case RESET_COMMON: {
      return {
        ...initialState,
        locationType: state.locationType,
        supplierId: state.supplierId,
      }
    }

    default:
      return state
  }
}
