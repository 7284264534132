import { Grid, Button, Heading } from '@enterprise-ui/canvas-ui-react'
import { global } from '../../../../constants/home'
import { useGridCommon } from '../useGridCommon'

const GridHead = (props) => {
  const { rowCnt, exportCsv } = props
  const { pluralize } = useGridCommon(props)
  return (
    <>
      <Grid.Item
        className="hc-pl-normal vm-button-selection"
        lg={4}
        md={6}
        xs={6}
      />
      <Grid.Item className="hc-ta-center" lg={4} md={2} xs={3}>
        <Heading size={2} className="hc-fs-subheading hc-mt-normal">
          {rowCnt > 0 ? pluralize(rowCnt, global.supplier, 's') : ''}
        </Heading>
      </Grid.Item>
      <Grid.Item className="hc-ta-right" lg={4} md={4} xs={3}>
        <Button
          type="secondary"
          className="hc-ml-dense"
          onClick={(e) => exportCsv(e)}
        >
          Export
        </Button>
      </Grid.Item>
    </>
  )
}

export default GridHead
