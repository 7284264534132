import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
  resetContact,
  resetRelationship,
  resetSupplier,
} from '../../store/Supplier/actionCreator'
import { resetProfile } from '../../store/Home/actionCreator'

const GridLinkTemplate = ({ url, params, type = '' }) => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const navigateTo = (event, url) => {
    event.preventDefault()
    if (url && type) {
      resetContact(dispatch)
      resetProfile(dispatch)
      resetSupplier(dispatch)
      resetRelationship(dispatch)
      history(url, {
        state: { tab: 'locations' },
      })
    }
    if (url && !type) {
      resetContact(dispatch)
      resetProfile(dispatch)
      resetSupplier(dispatch)
      resetRelationship(dispatch)
      history(url)
    }
  }
  return (
    <>
      <div style={{ display: 'inline-grid' }}>
        <span className="hc-fs-caption vm-text-ellipsis">
          <Link
            className="hc-clr-contrast"
            to={url}
            onClick={(event) => {
              navigateTo(event, url)
            }}
          >
            <p className="vm-text-ellipsis hc-mb-none">{params}</p>
          </Link>
        </span>
      </div>
    </>
  )
}

export default GridLinkTemplate
