/**
 * @enum
 * List of all available pages to determine which page is currently visible
 */
export const PAGE_TYPE = {
  // Init screen where user input email
  INIT_ADD: 'INIT_ADD',
  INIT_EDIT: 'INIT_EDIT',
  INIT_EDIT_VIEW: 'INIT_EDIT_VIEW',
  INIT_EDIT_UPDATE: 'INIT_EDIT_UPDATE',
  INIT_SHARED: 'INIT_SHARED',
  // Details screen where user add or edit or view contact details
  ADD_DETAILS: 'ADD_DETAILS',
  EDIT_DETAILS: 'EDIT_DETAILS',
  EDIT_VIEW_DETAILS: 'EDIT_VIEW_DETAILS',
  EDIT_UPDATE_DETAILS: 'EDIT_UPDATE_DETAILS',
  SHARED_DETAILS: 'SHARED_DETAILS',
  // Confirmation screen when contact details successfully added or edited etc
  CONTACT_ADDED: 'CONTACT_ADDED',
  CONTACT_EDITED: 'CONTACT_EDITED',
  CONTACT_SHARED: 'CONTACT_SHARED',
}

/**
 * @enum
 * To determine which type of page is should be visible to user based on api response (operation field in api response).
 */
export const PARAM_PAGE_TYPE = {
  ADD: PAGE_TYPE.ADD_DETAILS,
  EDIT: PAGE_TYPE.INIT_EDIT,
  SHARED: PAGE_TYPE.INIT_SHARED,
  VIEW: PAGE_TYPE.INIT_EDIT_VIEW,
  UPDATE: PAGE_TYPE.INIT_EDIT_UPDATE,
}
