import * as ACTIONS from './actionType'

export const supplierInfoState = {
  info: {
    loading: null,
    error: false,
    supplierInfo: {},
    supplierOverview: {},
    presupplierInfo: {},
  },
  alert: {
    loading: null,
    error: false,
    supplierAlert: [],
  },
  markAsDone: {
    loading: false,
    error: false,
    data: '',
  },
  paymentInfo: {
    loading: false,
    error: false,
    data: [],
  },
  internalData: {
    id: '',
  },
  putCompanyOfficer: {
    loading: false,
    error: false,
    data: '',
  },
  todoOverview: {
    loading: false,
    error: false,
    data: {},
  },
  supplierTodos: {
    loading: false,
    error: false,
    data: {},
  },
  tabData: {},
}
export default function supplierInfo(state = supplierInfoState, action = {}) {
  let hasAlert, obj
  switch (action.type) {
    case ACTIONS.TYPE.SUPPLIER_INFO_INIT:
      return {
        ...state,
        info: {
          loading: true,
          error: false,
          supplierInfo: {},
        },
      }
    case ACTIONS.TYPE.SUPPLIER_INFO:
      // if (action?.payload?.meta_info?.error) {
      //   res = action?.payload?.meta_info?.error
      // }
      return {
        ...state,
        info: {
          ...state.info,
          loading: false,
          supplierInfo: action.payload,
        },
      }
    case ACTIONS.TYPE.SUPPLIER_INFO_FAIL:
      return {
        ...state,
        info: {
          loading: false,
          error: action.error,
          supplierInfo: {},
        },
      }
    case ACTIONS.TYPE.PRE_SUPPLIER_INFO:
      return {
        ...state,
        info: {
          ...state.info,
          presupplierInfo: action.payload,
        },
      }
    case ACTIONS.TYPE.ALERT_INFO_INIT:
      return {
        ...state,
        alert: {
          loading: true,
          error: false,
          supplierAlert: {},
        },
      }
    case ACTIONS.TYPE.ALERT_INFO:
      hasAlert = Object.values(action.payload).some((value) => value === false)
      obj = { hasAlert: hasAlert, alerts: action.payload }
      return {
        ...state,
        alert: {
          loading: false,
          supplierAlert: { ...obj },
        },
      }
    case ACTIONS.TYPE.ALERT_INFO_FAIL:
      return {
        ...state,
        alert: {
          loading: false,
          error: action.error,
          supplierInfo: {},
        },
      }
    case ACTIONS.TYPE.MARK_DONE_INIT:
      return {
        ...state,
        markAsDone: {
          loading: true,
          error: false,
          data: '',
        },
      }
    case ACTIONS.TYPE.MARK_DONE_INFO:
      return {
        ...state,
        markAsDone: {
          loading: false,
          data: Array.isArray(action.payload)
            ? action.payload[0]
            : action.payload,
        },
      }
    case ACTIONS.TYPE.MARK_DONE_FAIL:
      return {
        ...state,
        markAsDone: {
          loading: false,
          error: true,
        },
      }
    case ACTIONS.TYPE.PAYMENT_INFO_INIT:
      return {
        ...state,
        paymentInfo: {
          loading: true,
          error: null,
        },
      }

    case ACTIONS.TYPE.PAYMENT_INFO_INFO:
      return {
        ...state,
        paymentInfo: {
          loading: false,
          data: action.data,
        },
      }
    case ACTIONS.TYPE.PAYMENT_INFO_FAIL:
      return {
        ...state,
        paymentInfo: {
          loading: false,
          error: action.payload?.error?.error,
          data: [],
        },
      }
    case ACTIONS.TYPE.SUPPLIER_PROFILE_RESET:
      return supplierInfoState

    case ACTIONS.TYPE.MARK_DONE_RESET:
      return {
        ...state,
        markAsDone: supplierInfoState.markAsDone,
      }
    case ACTIONS.TYPE.SET_SUPPLIER_ID: {
      let id = {
        ...state,
        internalData: {
          id: action.id,
        },
      }
      return id
    }

    case ACTIONS.TYPE.SET_SUPPLIER_TOKEN:
      return {
        ...state,
        token: action.payload,
      }

    case ACTIONS.TYPE.SUPPLIER_OVERVIEW: {
      return {
        ...state,
        info: {
          ...state.info,
          supplierOverview: action.payload,
        },
      }
    }
    case ACTIONS.TYPE.PUT_COMPANY_INIT:
      return {
        ...state,
        putCompanyOfficer: {
          loading: true,
          error: false,
          data: null,
        },
      }
    case ACTIONS.TYPE.PUT_COMPANY_INFO:
      obj = {
        ...state,
        putCompanyOfficer: {
          loading: false,
          data: 'success',
        },
        // info: {
        //   supplierInfo: {
        //     ...state.info.supplierInfo,
        //     company_officers: action.payload,
        //   },
        // },
      }
      return obj
    case ACTIONS.TYPE.PUT_COMPANY_FAIL:
      return {
        ...state,
        putCompanyOfficer: {
          loading: false,
          error: true,
        },
      }
    case ACTIONS.TYPE.REST_COMPANY_INFO:
      return {
        ...state,
        putCompanyOfficer: {
          loading: false,
          error: false,
          data: null,
        },
      }

    case ACTIONS.TYPE.OVERVIEW_TODOS_INIT:
      return {
        ...state,
        todoOverview: {
          loading: true,
          error: false,
          data: null,
        },
      }

    case ACTIONS.TYPE.OVERVIEW_TODOS_INFO:
      return {
        ...state,
        todoOverview: {
          loading: false,
          error: false,
          data: action.payload,
        },
      }

    case ACTIONS.TYPE.OVERVIEW_TODOS_FAIL:
      return {
        ...state,
        todoOverview: {
          loading: false,
          error: true,
        },
      }

    case ACTIONS.TYPE.TODOS_STATUS_INIT:
      return {
        ...state,
        supplierTodos: {
          loading: true,
          error: false,
          data: null,
        },
      }

    case ACTIONS.TYPE.TODOS_STATUS_INFO:
      return {
        ...state,
        supplierTodos: {
          loading: false,
          error: false,
          data: action.payload,
        },
      }

    case ACTIONS.TYPE.TODOS_STATUS_FAIL:
      return {
        ...state,
        supplierTodos: {
          loading: false,
          error: true,
        },
      }

    case ACTIONS.TYPE.GET_SUPPLIER_TABS:
      return {
        ...state,
        tabData: action.payload,
      }
    case ACTIONS.TYPE.TARGET_SETUP_INFO:
      return {
        ...state,
        targetSetUp: {
          data: action.data,
          loading: false,
          error: '',
        },
      }

    default:
      return state
  }
}
