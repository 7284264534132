export const global = {
  overview: 'Overview',
  profile: 'Profile',
  payment: 'Target Vendor #s',
  contact: 'Contact',
  location: 'Location',
  section: 'Section',
  information: 'Information',
  more: 'More',
  supplier: 'supplier',
  item: 'item',
  invites: 'Invites',
  brand: 'Brands',
  documents: 'Documents',
  relationship: 'Relationship',
}
export const profile = {
  basic: 'Basic',
  supplierNumber: 'Supplier Number',
  legalCompanyName: 'Legal Company Name',
  alternateCompanyName: 'Alternate Company Name',
  ultimateGlobalParent: 'Ultimate Global Parent',
  subsidiaries: 'Subsidiaries',
  productCategories: 'Product categories',
  HQAddress: 'Headquarters Address',
  yearStarted: 'Year Started',
  website: 'Website',
  companyType: 'Company Type',

  targetSetup: 'Target Setup',
  supplierTypeSubtype: 'Supplier Types and Subtypes',
  departments: 'Departments',
  relationship: 'Relationships',

  payment: 'Target Vendor #s',
  paymentNumber: 'Target Vendor #s',

  contacts: 'Contacts',
  totalContacts: 'Total Contacts',
  administrators: 'Administrators',
  sharedContacts: 'Shared Contacts',

  locations: 'Locations',
  totalLocations: 'Total Locations',
  manufacturingLocations: 'Manufacturing Locations',
  shippingLocations: 'Shipping Locations',

  brands: 'Brands',
  brandText: 'Manage Brands that will be tied to items.',

  documents: 'Documents',
  documentText:
    'Manage basic Documents that are needed for working with Target.',

  keyDocuments: 'Key Documents',
  relationships: 'Relationships',

  InfoLastUpdated: 'Information Last Updated',
  DUNSNo: ' DUNs #',
  latestDunsSync: 'Latest Dun & Bradstreet Sync',
  phone: 'Phone',
  stockSymbol: 'Stock Symbol',
  annualSale: 'Annual Revenue',
  CEO: 'Chief Executive Officer  ',
  employees: 'Employees',
}

export const tab = {
  contact: 'Contacts',
  location: 'Locations',
  brand: 'Brands',
  relationship: 'Relationships',
}
