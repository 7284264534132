import linkConfig from '../../../config/linkConfig'
/*
const commonLinks = [
  {
    id: 1,
    label: 'Home',
    href: '/',
    allowed: [],
  },
]
 */
const externalLinks = [
  {
    id: 2,
    label: 'Location',
    iconLeft: 'PlusIcon',
    href: '/location',
    allowed: [],
    // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
  },
  {
    id: 3,
    label: 'Contact',
    iconLeft: 'PlusIcon',
    href: '/contact',
    allowed: [],
    // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
  },
]
const internalLinks = [
  {
    id: 9,
    label: 'My Invites',
    iconLeft: 'GroupIcon',
    href: '/internal/invites',
    allowed: [
      'APP-SM-SYSADMIN',
      'APP-SM-APPROVE',
      'APP-SM-SYSADMIN-STAGE',
      'APP-SM-APPROVE-STAGE',
      'APP-SM-UPDATE',
      'APP-SM-UPDATE-STAGE',
    ],

    // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
  },
  {
    id: 5,
    label: 'Suppliers',
    href: '/',
    iconLeft: 'TruckIcon',
    allowed: [],
    // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
  },
  {
    id: 10,
    label: 'Locations',
    iconLeft: 'StoreMapIcon',
    href: '/internal/location-search',
    allowed: [],
  },
  // {
  //   id: 12,
  //   label: 'Brands',
  //   iconLeft: 'StoreMapIcon',
  //   href: '/internal/brands',
  //   allowed: [],
  // },
  {
    id: 6,
    label: 'For Review',
    iconLeft: 'WorkIcon',
    href: '#',
    allowed: [
      'APP-SM-SYSADMIN',
      'APP-SM-APPROVE',
      'APP-SM-SYSADMIN-STAGE',
      'APP-SM-APPROVE-STAGE',
    ],
    // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
    children: [
      {
        label: 'Pending Shipping Details',
        href: '/internal/location/pending',
        //iconLeft: 'PlusIcon',
        allowed: [],
        // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
      },
      {
        label: 'Addresses',
        href: '/internal/location/review',
        //iconLeft: 'PlusIcon',
        allowed: [],
        // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
      },
      {
        label: 'Sanctioned Addresses',
        href: '/internal/location/sanctioned',
        //iconLeft: 'PlusIcon',
        allowed: [],
        // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
      },
      {
        label: 'Security Details',
        href: '/internal/location/security',
        //iconLeft: 'PlusIcon',
        allowed: [],
        // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
      },
      {
        label: 'Supply Chain Certs',
        href: '/internal/supply-chain',
        //iconLeft: 'PlusIcon',
        allowed: [],
        // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
      },
      {
        label: 'Duns Review Requests',
        href: '/internal/duns-match',
        //iconLeft: 'PlusIcon',
        allowed: [],
        // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
      },
    ],
  },
  {
    id: 7,
    label: 'Legacy VMM',
    href: linkConfig.vmm.site,
    iconLeft: 'ImageIcon',
    allowed: [],
    icon: 'faExternalLinkAlt',
    // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
  },
  {
    id: 8,
    label: 'Manufacturing  Visibility',
    href: linkConfig.mvs,
    iconLeft: 'ImageIcon',
    allowed: [],
    icon: 'faExternalLinkAlt',
    // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
  },
  {
    id: 11,
    label: 'Training',
    href: 'https://targetonline.sharepoint.com/:f:/r/sites/BusinessPartnerCommunication/Shared%20Documents/Supplier%20Management?csf=1&web=1&e=6G6Ssa',
    allowed: [],
    iconLeft: 'TeacherIcon',
  },
  /*
  {
    id: 4,
    label: 'Invite',
    href: '#',
    allowed: [],
    // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
    children: [
      {
        label: 'Invite',
        href: '/internal/invite',
        allowed: [],
        // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
      },
      {
        label: 'List',
        href: '/internal/invites',
        allowed: [],
        // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
      },
    ],
  },
  {
    id: 5,
    label: 'Locations',
    href: '#',
    allowed: [],
    // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
    children: [
      {
        label: 'Pending location',
        href: '/internal/location/pending',
        allowed: [],
        // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
      },
      {
        label: 'For review',
        href: '/internal/location/review',
        allowed: [],
        // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
      },
    ],
  },
  {
    id: 3,
    label: 'Contact',
    href: '/internal/contact/',
    allowed: [],
    // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
  },
  {
    id: 7,
    label: 'Suppliers',
    href: '/',
    allowed: [],
    // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
    children: [
      {
        label: 'Profiles',
        href: '/',
        tab: 'profile',
        allowed: [],
        // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
      },
      {
        label: 'Locations',
        href: '/',
        tab: 'location',
        allowed: [],
        // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
      },
      {
        label: 'Contacts',
        href: '/',
        tab: 'contact',
        allowed: [],
        // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
      },
      {
        label: 'Payment',
        href: '/',
        tab: 'payment',
        allowed: [],
        // allowed: ['APP-OAUTH2-POL-NPE-ADMIN'],
      },
    ],
  },*/
]

export const siteNavList = (memberof, user) => {
  const userLink = user ? externalLinks : internalLinks
  // const links = [...commonLinks, ...userLink]
  const links = [...userLink]
  const groups = memberof ? memberof : []
  let menu = links.filter(function (item) {
    return item.allowed.length === 0
      ? true
      : groups.some((v) => item.allowed.indexOf(v) !== -1)
  })
  return menu
}
