/** user data */
export const BRAND_NAME = 'BRAND_NAME'
export const BRAND_ABBREVIATION = 'BRAND_ABBREVIATION'
export const BRAND_DESCRIPTION = 'BRAND_DESCRIPTION'
export const BRAND_TYPE = 'BRAND_TYPE'
export const BRAND_CLASSIFICATION = 'BRAND_CLASSIFICATION'
export const BRAND_DEPARTMENT = 'BRAND_DEPARTMENT'
export const BRAND_DIVISION = 'BRAND_DIVISION'
export const BRAND_REQUESTED_BY = 'BRAND_REQUESTED_BY'
export const BRAND_OWNER = 'BRAND_OWNER'
export const BRAND_EXCLUSIVE_TO_TARGET = 'BRAND_EXCLUSIVE_TO_TARGET'
export const BRAND_ROYALTY_PAY = 'BRAND_ROYALTY_PAY'
export const BRAND_PERSONALITY = 'BRAND_PERSONALITY'
export const BRAND_PROPERTY = 'BRAND_PROPERTY'
export const BRAND_USER_DATA = 'BRAND_USER_DATA'
export const RESET_USER_DATA = 'RESET_USER_DATA'

/** api data */
export const BRAND_INFO_INIT = 'BRAND_INFO_INIT'
export const BRAND_INFO = 'BRAND_INFO'
export const BRAND_INFO_FAIL = 'BRAND_INFO_FAIL'
export const EDIT_BRAND_DESCRIPTION = 'EDIT_BRAND_DESCRIPTION'
export const BRAND_ID = 'BRAND_ID'

/** common */
export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const PURGE_AND_RESET_DATA = 'PURGE_AND_RESET_DATA'
export const SET_VISIBLE = 'SET_VISIBLE'
export const SET_DISABLE = 'SET_DISABLE'
export const DEPARTMENT_INFO = 'DEPARTMENT_INFO'
export const DEPARTMENT_FAIL = 'DEPARTMENT_FAIL'
