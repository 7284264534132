import { useState, useRef, useCallback } from 'react'

export const useGrid = () => {
  const gridApi = useRef()
  const columnApi = useRef()
  const [gridReady, setGridReady] = useState(false)

  const onGridReady = (params) => {
    gridApi.current = params.api
    columnApi.current = params.columnApi
    setGridReady(true)
  }

  const getColumnApi = useCallback(() => {
    return columnApi.current
  }, [])

  const getGridApi = useCallback(() => {
    return gridApi.current
  }, [])

  return {
    gridReady,
    onGridReady,
    getGridApi,
    getColumnApi,
  }
}
