import React from 'react'
import { Grid, Heading, Button } from '@enterprise-ui/canvas-ui-react'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: undefined, errorInfo: undefined }
  }

  componentDidCatch(catchedError, catchedErrorInfo) {
    this.setState({
      error: catchedError,
      errorInfo: catchedErrorInfo,
    })
  }

  render() {
    const { children } = this.props
    const { error, errorInfo } = this.state

    if (error) {
      // This is for developers
      console.error(error)
      console.error(errorInfo?.componentStack)

      // This is for end users
      return (
        <>
          <Grid.Container className="hc-ta-center">
            <Grid.Item xs={12} className="hc-mt-expanded">
              <Heading size={4} className="hc-clr-error">
                Something Went wrong. Please try again after some time.
              </Heading>
            </Grid.Item>

            <Grid.Item xs={12} className="hc-mt-normal hc-mb-expanded">
              <Button
                type="secondary"
                onClick={() => window.location.reload(true)}
              >
                Try Again
              </Button>
            </Grid.Item>
          </Grid.Container>
        </>
      )
    }
    return children
  }
}
