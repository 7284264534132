import { Grid, Input } from '@enterprise-ui/canvas-ui-react'

import { countryTypes } from '../../../Supplier/Location/MenuConstants'
import { useGridCommon } from '../useGridCommon'

const Filters = (props) => {
  const { selectType, selectFilter, onQuickFilterText, data: filerData } = props
  const { setFilterData, defaultShipTerms, defaultReason } =
    useGridCommon(props)

  const shipTerms = setFilterData(filerData, 'Terms', defaultShipTerms)

  const reasonsList = setFilterData(filerData, 'Reason', defaultReason)

  return (
    <>
      <Grid.Item lg={6} xs={12} className="vm-normal-padding">
        <div className="hc-ml-normal hc-mr-normal">
          <Input.Select
            id="Location"
            className="shipping-details-filter hc-mr-normal"
            style={{ width: '30%' }}
            defaultValue="any"
            options={countryTypes.map((t) => ({
              value: t.value,
              label: t.name,
            }))}
            onUpdate={(id, value) => {
              selectFilter(id, value)
            }}
            value={selectType.location}
          />
          <Input.Select
            id="Terms"
            className="shipping-details-filter hc-mr-normal"
            style={{ width: '30%' }}
            defaultValue="any"
            options={shipTerms}
            onUpdate={(id, value) => {
              selectFilter(id, value)
            }}
            value={selectType.terms}
          />
          <Input.Select
            id="Reason"
            style={{ width: '30%' }}
            defaultValue="any"
            options={reasonsList}
            onUpdate={(id, value) => {
              selectFilter(id, value)
            }}
            value={selectType.reason}
          />
        </div>
      </Grid.Item>
      <Grid.Item lg={3} xs={0} className="hc-pa-none" />
      <Grid.Item lg={3} md={8} xs={11} className="vm-normal-padding">
        <div className="hc-ml-normal hc-mr-normal">
          <Input.Text
            type="text"
            id="quickFilter"
            onChange={onQuickFilterText}
            placeholder="Search Supplier"
          />
        </div>
      </Grid.Item>
    </>
  )
}

export default Filters
