import { useState, createContext } from 'react'
import { Layout, Card } from '@enterprise-ui/canvas-ui-react'
import { useLocation } from 'react-router-dom'
import { withAuth, useAuth } from '@praxis/component-auth'
import SideNavigation from './SideNav/SideNav'
import VMRoutes from './Routes'
import VMHeader from './Header/Header'
import { checkUser, showSideNav } from '../../utills/Common'
import ErrorBoundary from '../common/ErrorBoundry'

export const ThemeContext = createContext()
const VMLayout = () => {
  const auth = useAuth()
  const [isSideNavOpen, setIsSideNavOpen] = useState(false)
  const [theme, setTheme] = useState('default')
  const [isDarkMode, setIsDarkMode] = useState(
    window?.matchMedia?.('(prefers-color-scheme: dark)')?.matches || false
  )
  const toggleNavOpen = () => setIsSideNavOpen(!isSideNavOpen)
  // const toggleNavOpen = () => setIsSideNavOpen(true)
  const location = useLocation()
  const page = location.pathname.replace(/[\\/][^\\/]*$/, '')
  const isRegister = page === '/invite-verification'
  const { isAuthenticated, session } = auth
  const user = checkUser(session)
  const showNav = showSideNav(user.isUser, location, isRegister)
  return (
    <>
      <ErrorBoundary>
        <ThemeContext.Provider value={isDarkMode}>
          <Layout
            className={isRegister ? 'vm-register-page' : ''}
            darkMode={isDarkMode}
            theme={theme}
          >
            <div class="C-Layout__headerBackground" style={{ zIndex: 9 }}></div>
            {!isRegister && (
              <Layout.Header style={{ zIndex: 9 }}>
                <VMHeader
                  isSideNavOpen={isSideNavOpen}
                  onRequestClose={toggleNavOpen}
                  setIsDarkMode={setIsDarkMode}
                  setTheme={setTheme}
                  auth={auth}
                  isDarkMode={isDarkMode}
                  showNav={showNav}
                />
              </Layout.Header>
            )}
            {isAuthenticated() && showNav && !isRegister && (
              <SideNavigation
                isSideNavOpen={isSideNavOpen}
                onRequestClose={toggleNavOpen}
                setIsDarkMode={setIsDarkMode}
                isDarkMode={isDarkMode}
                setTheme={setTheme}
                auth={auth}
              />
            )}
            <Layout.Body
              includeRail
              className={`${
                isSideNavOpen && !isRegister
                  ? 'vm-small-side-nav-margin'
                  : !user.isUser
                  ? 'vm-with-side-nav'
                  : ''
              } section-wrapper ${isDarkMode ? '' : 'default-background'}`}
            >
              <Card
                corners="none"
                className="vm-supplier-tabs-card"
                elevation={0}
              >
                <VMRoutes {...auth} />
              </Card>
            </Layout.Body>
          </Layout>
        </ThemeContext.Provider>
      </ErrorBoundary>
    </>
  )
}

export default withAuth()(VMLayout)
