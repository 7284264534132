import { checkUser } from '../../../utills/Common'
import { useFirefly } from '../../../utills/useAnalytics'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useAuth } from '@praxis/component-auth'
import useBreadcrumbs from './useBreadcrumb'
import { useSelector } from 'react-redux'

export const HeaderBreadcrumb = (props) => {
  const auth = useAuth()
  const { session } = auth
  const user = checkUser(session)

  const supplierInternalData = useSelector((state) => {
    const { supplierInfo } = state
    return supplierInfo.internalData
  }, [])

  const { handleFireflyClickEvent } = useFirefly()
  const capitalize = ([first, ...rest]) =>
    first.toUpperCase() + rest.join('').toLowerCase()

  let internalHome = []
  const home = [
    {
      name: 'Suppliers',
      path: '/',
    },
  ]
  const homeInternal = [
    {
      name: 'Internal',
      path: '/',
    },
  ]

  if (!user.isUser) {
    internalHome = [
      {
        name: 'Supplier Details',
        path: `/supplier-view/${supplierInternalData.id}`,
      },
    ]
  }

  const navigationPath = [
    {
      path: '/supplier-view/:supplier_id',
      breadCrumb: [home, internalHome],
    },
    {
      name: 'To Do',
      path: '/supplier/:name/:supplier_id?/:id?',
      breadCrumb: [home, internalHome],
      paramsName: { name: 'todo' },
    },
    {
      name: 'Basic Information',
      path: '/supplier/:name/:supplier_id?/:id?',
      breadCrumb: [home, internalHome],
      paramsName: { name: 'profile' },
    },
    {
      name: 'Pending shipping details',
      path: '/internal/:module?/:type?/:id?',
      breadCrumb: [homeInternal],
      paramsName: { type: 'pending' },
    },
    {
      name: 'Review addresses list',
      path: '/internal/:module?/:type?/:id?',
      breadCrumb: [homeInternal],
      paramsName: { type: 'review' },
    },
    {
      name: 'Review sanctioned addresses list',
      path: '/internal/:module?/:type?/:id?',
      breadCrumb: [homeInternal],
      paramsName: { type: 'sanctioned' },
    },
    {
      name: 'Review security details',
      path: '/internal/:module?/:type?/:id?',
      breadCrumb: [homeInternal],
      paramsName: { type: 'security' },
    },
    {
      name: 'Invites list',
      path: '/internal/:module?/:type?/:id?',
      breadCrumb: [homeInternal],
      paramsName: { module: 'invites' },
    },
    {
      name: 'Add Invite',
      path: '/internal/:module?/:type?/:id?',
      breadCrumb: [homeInternal],
      paramsName: { module: 'invite' },
    },
  ]

  const useData = useBreadcrumbs(navigationPath)
  const breadcrumbs = useData[0]?.routhPaths
  const filteredData = breadcrumbs?.breadCrumb?.filter((ruts) => ruts.length)

  return (
    <>
      {filteredData?.length > 0 ? (
        <div className="sm_flex">
          {filteredData?.map((item, index) => {
            return (
              <span key={index} className="hc-ml-dense">
                {item && item[0] && (
                  <Link
                    to={item[0].path}
                    exact="true"
                    className="vmm_flex_center"
                    onClick={() => {
                      handleFireflyClickEvent(item[0].name)
                    }}
                  >
                    <span className="hc-fs-body hc-clr-grey05 font-force-white">
                      {item[0].name}
                      {filteredData.length !== index + 1 && (
                        <FontAwesomeIcon
                          icon={faCaretRight}
                          className="hc-mr-dense hc-ml-dense"
                        />
                      )}
                    </span>
                  </Link>
                )}
              </span>
            )
          })}
          {breadcrumbs?.paramsName && (
            <span>
              <FontAwesomeIcon
                icon={faCaretRight}
                className="hc-mr-dense hc-ml-dense font-force-white"
              />
              {breadcrumbs.name
                ? capitalize(breadcrumbs.name)
                : capitalize(breadcrumbs.paramsName.name)}
            </span>
          )}
        </div>
      ) : (
        <Link
          to="/"
          exact="true"
          className="vmm_flex_center"
          onClick={() => {
            handleFireflyClickEvent('Home')
          }}
        >
          <p className="hc-fs-body hc-clr-grey05 font-force-white">
            {!user.isUser ? `Suppliers` : ''}
          </p>
        </Link>
      )}
    </>
  )
}

export default HeaderBreadcrumb
