import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLocationDashboard } from './../../../store/Internal/actionCreator'

export const useLocationFetch = (queryType) => {
  const dispatch = useDispatch()
  const { data, loading, error } = useSelector((state) => {
    return {
      error: state.internal?.locationInfo?.error,
      data: state.internal?.locationInfo?.data,
      loading: state.internal?.locationInfo?.loading,
    }
  })

  useEffect(() => {
    dispatch(getLocationDashboard(queryType))
  }, [dispatch, queryType])

  /********* End AG Grid Configs **************/

  return {
    data,
    loading,
    error,
  }
}
