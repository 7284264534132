import { matchPath, useLocation } from 'react-router'

const getBreadcrumbs = ({ location, options }) => {
  let breadcrumb
  const { pathname } = location
  breadcrumb = options?.map((routhPaths) => {
    const match = matchPath(
      {
        path: routhPaths.path,
        exact: true,
        strict: true,
      },
      pathname
    )
    const { paramsName } = routhPaths
    const objLength = paramsName ? Object.keys(paramsName)?.length : 0

    let checkPath
    if (paramsName && match) {
      const key = Object.keys(paramsName)[0]
      checkPath = match.params[key] === paramsName[key]
    }
    if (match) {
      if (objLength > 0 && !checkPath) return null
      return { routhPaths }
    } else {
      return null
    }
  })

  return breadcrumb?.filter((e) => e)
}

const useReactRouterBreadcrumbs = (options) => {
  const result = getBreadcrumbs({
    location: useLocation(),
    options,
  })
  return result
}
export default useReactRouterBreadcrumbs
