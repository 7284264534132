import { useEffect, useState, useRef } from 'react'

import {
  Button,
  Grid,
  GridContainer,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'

import { forceSignOut } from '../../utills/Common'

import dayjs from 'dayjs'

const expirationTimeout = 15

const SessionHandler = () => {
  const [showModal, setShowModal] = useState(false)
  const [expirationTimer, setExpirationTimer] = useState(0)
  const isModalMounted = useRef(false)

  const auth = useAuth()
  const { session } = auth
  const sessionExpiration = session?.access?.exp

  const sessionExpirationTimestamp = sessionExpiration
    ? dayjs.unix(sessionExpiration)
    : ''

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = dayjs()

      const timeDifference = sessionExpirationTimestamp?.diff(
        currentTime,
        'minute'
      )

      if (timeDifference <= expirationTimeout) {
        !isModalMounted.current && setShowModal(true)
        isModalMounted.current = true

        if (timeDifference <= 0) {
          showModal && setShowModal(false)
          forceSignOut()
        }

        setExpirationTimer(timeDifference)
      }
    }, 60000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="hc-ta-left hc-clr-contrast">
      <Modal
        headingText={`You will be logged out soon.`}
        isVisible={showModal}
        onRefuse={() => setShowModal(false)}
        onApproveModal={() => setShowModal(false)}
        className="sm-modal-zIndex"
      >
        <div className="hc-pa-normal">
          <GridContainer>
            <Grid.Item xs>
              You have been logged in for almost 8 hours. Whew! For security,
              you will be logged out of all Target tabs in {expirationTimer}{' '}
              minutes. Save your work in all tabs so you do not lose anything.
            </Grid.Item>
          </GridContainer>
          <GridContainer direction="row-reverse" spacing="dense">
            <Grid.Item>
              <Button
                onClick={() => {
                  setShowModal(false)
                }}
              >
                CLOSE
              </Button>
            </Grid.Item>
          </GridContainer>
        </div>
      </Modal>
    </div>
  )
}

export default SessionHandler
