/**
 * Utility function to get an object with keys of passed object and keeping the values to false
 * @param {Object} paramObj - It will be used to extract all keys
 * @returns {Object} - It will have all extracted keys with value "false"
 * @example
 * {
 *    LOCATION_INFO: false
 *  }
 */

export const getObjWithFalseVal = (paramObj) => {
  return Object.keys(paramObj).reduce((obj, compName) => {
    obj[compName] = false
    return obj
  }, {})
}
