// import '@praxis/react-app-polyfill/ie11'
// import '@praxis/react-app-polyfill/stable'
import React from 'react'
import * as serviceWorker from './serviceWorker'
import { createRoot } from 'react-dom/client'
import App from './App'

const rootEl = document.getElementById('root')
const createdRoot = createRoot(rootEl)
createdRoot.render(<App />)

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    //ReactDOM.render(<NextApp />, rootEl) -> removed because react version upgraded to 18
    createdRoot.render(
      <React.StrictMode>
        <NextApp />
      </React.StrictMode>
    )
  })
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
