import { combineReducers } from 'redux'
import notification from './Notification/reducer'
import supplierInfo from './Home/reducer'
import supplier from './Supplier/reducer'
import internal from './Internal/reducer'
// import locationList from './locationList/reducer'
import contact from './Contact/reducer'
import companyTree from './CompanyTree/reducer'

import { commonReducer } from './Common/common/reducer'
import { apiDataReducer } from './Common/apiData/reducer'
import { userDataReducer } from './Common/userData/reducer'

import { brandCommonReducer } from './Brand/common/reducer'
import { brandApiDataReducer } from './Brand/apiData/reducer'
import { brandUserDataReducer } from './Brand/userData/reducer'

const rootReducer = combineReducers({
  notification,
  supplierInfo,
  supplier,
  internal,
  contact,
  companyTree,
  common: commonReducer,
  apiData: apiDataReducer,
  userData: userDataReducer,
  brandCommon: brandCommonReducer,
  brandApiData: brandApiDataReducer,
  brandUserData: brandUserDataReducer,
})

export default rootReducer
