import { Link } from 'react-router-dom'
import { isNull, isUndefined } from 'lodash'

import { useFirefly } from './../../../utills/useAnalytics'
import { handleFocusElem } from '../../../utills/Common'

export const SkipLinks = ({ onRequestClose, isSideNavOpen, showNav }) => {
  const { handleFireflyClickEvent } = useFirefly()

  const handleClick = (e) => {
    const command = e.target.getAttribute('data-command')
    let element = null,
      selector
    e.preventDefault()
    handleFireflyClickEvent(e)

    if (command === 'mainContent') {
      selector = document.querySelector(`#${command}`)
      element = selector.querySelector(`h1, h2, a, button`)
      if (!isNull(element) && !isUndefined(element)) {
        handleFocusElem(element)
      }
    } else if (command === 'sidenav') {
      onRequestClose()
    }
  }
  return (
    <div id="skipLinks">
      <Link
        to="#content"
        className="h-sr-only h-sr-only-focusable hc-clr-contrast-weak"
        data-command="mainContent"
        onClick={handleClick}
      >
        Skip to main content
      </Link>
      {showNav && (
        <Link
          to="#show-content"
          className="h-sr-only h-sr-only-focusable"
          data-command="sidenav"
          onClick={handleClick}
        >
          {`${
            isSideNavOpen ? 'Close side navigation' : 'Skip to side navigation'
          }`}
        </Link>
      )}
    </div>
  )
}
