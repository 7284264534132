/* Actions */
import * as Actions from '../actionType'

/* Initial State */
export const brandState = {}

export const brandApiDataReducer = (state = brandState, action = {}) => {
  const { type, payload, error } = action

  switch (type) {
    case Actions.BRAND_INFO_INIT: {
      return {
        ...state,
        brandInfo: {
          loading: true,
          error: false,
          data: {},
        },
      }
    }

    case Actions.BRAND_INFO: {
      return {
        ...state,
        brandInfo: {
          loading: false,
          error: false,
          data: payload,
        },
      }
    }

    case Actions.BRAND_INFO_FAIL: {
      return {
        ...state,
        brandInfo: {
          loading: false,
          error: error,
          data: {},
        },
      }
    }

    case Actions.EDIT_BRAND_DESCRIPTION: {
      return {
        ...state,
        brandInfo: {
          ...state.brandInfo,
          data: [
            {
              ...state.brandInfo.data[0],
              brand_description: payload,
            },
          ],
        },
      }
    }

    case Actions.BRAND_ID: {
      return {
        ...state,
        info: {
          ...state.info,
          brand_id: payload,
        },
      }
    }

    default:
      return state
  }
}
