/* Actions */
import * as Actions from '../actionType'

/* Initial State */
export const brandState = {}

export const brandUserDataReducer = (state = brandState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case Actions.BRAND_NAME: {
      return {
        ...state,
        info: {
          ...state.info,
          brand_name: payload,
        },
      }
    }

    case Actions.BRAND_ABBREVIATION: {
      return {
        ...state,
        info: {
          ...state.info,
          brand_abbreviation: payload,
        },
      }
    }

    case Actions.BRAND_DESCRIPTION: {
      return {
        ...state,
        info: {
          ...state.info,
          brand_description: payload,
        },
      }
    }

    case Actions.BRAND_TYPE: {
      return {
        ...state,
        info: {
          ...state.info,
          brand_type: payload,
        },
      }
    }

    case Actions.BRAND_CLASSIFICATION: {
      return {
        ...state,
        info: {
          ...state.info,
          brand_classification: payload,
        },
      }
    }

    case Actions.BRAND_DEPARTMENT: {
      return {
        ...state,
        info: {
          ...state.info,
          department: payload,
        },
      }
    }

    case Actions.BRAND_DIVISION: {
      return {
        ...state,
        info: {
          ...state.info,
          division: payload,
        },
      }
    }

    case Actions.BRAND_REQUESTED_BY: {
      return {
        ...state,
        info: {
          ...state.info,
          requested_by: payload,
        },
      }
    }

    case Actions.BRAND_OWNER: {
      return {
        ...state,
        info: {
          ...state.info,
          owned_by_target: payload,
        },
      }
    }

    case Actions.BRAND_EXCLUSIVE_TO_TARGET: {
      return {
        ...state,
        info: {
          ...state.info,
          exclusive_to_target: payload,
        },
      }
    }

    case Actions.BRAND_ROYALTY_PAY: {
      return {
        ...state,
        info: {
          ...state.info,
          royalty_pay_by_target: payload,
        },
      }
    }

    case Actions.BRAND_PERSONALITY: {
      return {
        ...state,
        info: {
          ...state.info,
          personality_or_character: payload,
        },
      }
    }

    case Actions.BRAND_PROPERTY: {
      return {
        ...state,
        info: {
          ...state.info,
          property: payload,
        },
      }
    }

    case Actions.RESET_USER_DATA: {
      return {
        ...brandState,
      }
    }

    default:
      return state
  }
}
