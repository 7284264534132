import { useAnalytics } from '@praxis/component-analytics'

export const useFirefly = (props) => {
  const { trackClick } = useAnalytics()
  const handleFireflyClickEvent = (event) => {
    let data

    if (event) {
      data = event
      if (typeof event === 'object') {
        const { target, currentTarget } = event
        data = target.text
          ? target.text
          : target.textContent
          ? target.textContent
          : currentTarget.textContent
          ? currentTarget.textContent
          : currentTarget.getAttribute('aria-label')
          ? currentTarget.getAttribute('aria-label')
          : ''
      }
    }
    if (data) {
      trackClick(data)
    }
  }

  return {
    handleFireflyClickEvent,
  }
}
