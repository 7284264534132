import { Suspense, lazy, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { ProtectedElement, isTargetDomain } from '@praxis/component-auth'
import { Spinner } from '@enterprise-ui/canvas-ui-react'

import requestInterceptor from '../Auth/requestInterceptor'
import { Notify } from '../common/Notification/Notification'
import {
  getSupplierToken,
  setSupplierToken,
} from '../../store/Home/actionCreator'
import { checkUser } from '../../utills/Common'
import { AccessGroups } from '../Auth/accessGroups'
import PendingLocation from '../../pages/Internal/Location/Pending'
import EditDunsResponse from '../../pages/Internal/EditDunsReview/EditDunsResponse'
const Home = lazy(() => import('./../../pages/Home/'))
const Register = lazy(() => import('./../../pages/Invite/'))
const Contact = lazy(() => import('./../../pages/Contact'))
const Location = lazy(() => import('./../../pages/Location'))
const SupplierDetails = lazy(() => import('./../../pages/Supplier/'))
const SupplierView = lazy(() => import('./../../pages/Supplier/SupplierView/'))
const AssignRoles = lazy(() => import('./../../pages/Supplier/AssignRoles'))
const Internal = lazy(() => import('./../../pages/Internal/'))
const Brand = lazy(() => import('../../pages/Brand'))
const SelectDunsPage1 = lazy(() =>
  import('../../pages/Internal/Invite/DunsMatch/SelectDunsPage1')
)
const NotFound = lazy(() => import('./../../pages/NotFound/'))
const Unauthorized = lazy(() => import('./../../pages/Unauthorized/'))
const Forbidden = lazy(() => import('../../pages/Unauthorized/Forbidden'))

const VMRoutes = (auth) => {
  const { session, login } = auth
  const { isAuthenticated } = auth
  const user = checkUser(session)
  const dispatch = useDispatch()
  const history = useNavigate()
  const { isPartnersOnline } = AccessGroups()
  const supplierToken = useSelector((state) => state?.supplierInfo?.token)
  const restrictPol = isPartnersOnline() && isAuthenticated() && !user.isUser

  const renderLoader = () => (
    <span role="alert">
      <Spinner size="dense" className="hc-clr-contrast" />
      Loading, please wait...
    </span>
  )
  useLayoutEffect(() => {
    requestInterceptor(dispatch, session, login)
    // First check if user is authenticated and user is internal
    if (isAuthenticated()) {
      if (!supplierToken) {
        // Lets see if we have it in local storage
        const savedSupplierToken = localStorage.getItem('X-USER-TOKEN')

        // If it is available in local storage, then populate it in redux store
        if (savedSupplierToken) {
          dispatch(setSupplierToken(savedSupplierToken))
          // Else fetch it from api now
        } else {
          dispatch(getSupplierToken())
        }
      }
      if (restrictPol) {
        history('/unauthorized')
      }
      // If user is not internal (Means he is accessing POL), and not authenticated, then remove this token if available.
    } else if (!isTargetDomain() || isTargetDomain()) {
      localStorage.removeItem('X-USER-TOKEN')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <Routes>
          <Route
            path="location"
            element={<ProtectedElement component={Location} />}
          />
          <Route
            path="contact/:id/:supplier_id"
            element={<ProtectedElement component={Contact} />}
          />
          <Route
            path="contact/:id"
            element={<ProtectedElement component={Contact} />}
          />
          <Route
            path="contact"
            element={<ProtectedElement component={Contact} />}
          />
          <Route
            path="assignRoles"
            element={<ProtectedElement component={AssignRoles} />}
          />
          <Route
            path="brand"
            element={<ProtectedElement component={Brand} />}
          />
          <Route
            path="brand/:id"
            element={<ProtectedElement component={Brand} />}
          />
          <Route
            path="supplier/:name/:supplier_id/:id"
            element={
              <ProtectedElement
                render={(props) => <SupplierDetails {...props} user={user} />}
                exactly
              />
            }
          />
          <Route
            path="supplier/:name"
            element={
              <ProtectedElement
                render={(props) => <SupplierDetails {...props} user={user} />}
                exactly
              />
            }
          />
          <Route
            path="supplier/:name/:supplier_id"
            element={
              <ProtectedElement
                render={(props) => <SupplierDetails {...props} user={user} />}
                exactly
              />
            }
          />

          <Route
            exactly
            path="invite-verification/:id"
            element={<Register />}
          />
          <Route
            exactly
            path="/internal/duns-match/:id"
            element={<SelectDunsPage1 />}
          />
          <Route
            exactly
            path="/internal/duns-match/edit-duns-response/:id"
            element={<EditDunsResponse />}
          />
          <Route
            exactly
            path="/internal/pending-locations-list"
            element={<PendingLocation />}
          />
          {restrictPol && <Route element={<Unauthorized />} />}
          {!user.isUser && (
            <Route
              path="/internal/:module"
              element={
                <ProtectedElement
                  render={(props) => <Internal {...props} user={user} />}
                />
              }
            />
          )}
          {!user.isUser && (
            <Route
              path="/internal/:module/:type"
              element={
                <ProtectedElement
                  render={(props) => <Internal {...props} user={user} />}
                />
              }
            />
          )}
          {!user.isUser && (
            <Route
              path="/internal/:module/:type/:id"
              element={
                <ProtectedElement
                  render={(props) => <Internal {...props} user={user} />}
                />
              }
            />
          )}
          {/* {!user.isUser && (
            <ProtectedRoute
            exactly
              path="/internal/duns-match/:id"
              component={SelectDunsPage1}
              // render={(props) => <SelectDunsPage1 {...props} user={user} />}
            />
          )} */}
          {!user.isUser && (
            <Route
              path="supplier-view/:supplier_id"
              element={
                <ProtectedElement
                  render={(props) => <SupplierView {...props} user={user} />}
                />
              }
            />
          )}
          {user.isUser ? (
            <Route
              exact
              path="/"
              element={
                <ProtectedElement
                  render={(props) => <Home {...props} user={user} />}
                />
              }
            />
          ) : (
            <Route
              exact
              path="/"
              element={
                <ProtectedElement
                  render={(props) => <Internal {...props} user={user} />}
                />
              }
            />
          )}
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/forbidden" element={<Forbidden />} />
          <Route element={<NotFound />} />
        </Routes>
      </Suspense>
      <Notify />
    </>
  )
}

export default VMRoutes
