export const TYPE = {
  SUPPLIER_INFO: 'SUPPLIER_INFO',
  PRE_SUPPLIER_INFO: 'PRE_SUPPLIER_INFO',
  SUPPLIER_INFO_INIT: 'SUPPLIER_INFO_INIT',
  SUPPLIER_INFO_FAIL: 'SUPPLIER_INFO_FAIL',
  TODOS_STATUS_INIT: 'TODOS_STATUS_INIT',
  TODOS_STATUS_INFO: 'TODOS_STATUS_INFO',
  TODOS_STATUS_FAIL: 'TODOS_STATUS_FAIL',
  ALERT_INFO: 'ALERT_INFO',
  ALERT_INFO_INIT: 'ALERT_INFO_INIT',
  ALERT_INFO_FAIL: 'ALERT_INFO_FAIL',
  MARK_DONE_INFO: 'MARK_DONE_INFO',
  MARK_DONE_INIT: 'MARK_DONE_INIT',
  MARK_DONE_FAIL: 'MARK_DONE_FAIL',
  PAYMENT_INFO_INFO: 'PAYMENT_INFO_INFO',
  PAYMENT_INFO_INIT: 'PAYMENT_INFO_INIT',
  PAYMENT_INFO_FAIL: 'PAYMENT_INFO_FAIL',
  SUPPLIER_PROFILE_RESET: 'SUPPLIER_PROFILE_RESET',
  MARK_DONE_RESET: 'MARK_DONE_RESET',
  SET_SUPPLIER_ID: 'SET_SUPPLIER_ID',
  SET_SUPPLIER_TOKEN: 'SET_SUPPLIER_TOKEN',
  SUPPLIER_OVERVIEW: 'SUPPLIER_OVERVIEW',
  PUT_COMPANY_INFO: 'PUT_COMPANY_INFO',
  PUT_COMPANY_INIT: 'PUT_COMPANY_INIT',
  PUT_COMPANY_FAIL: 'PUT_COMPANY_FAIL',
  REST_COMPANY_INFO: 'REST_COMPANY_INFO',
  OVERVIEW_TODOS_INFO: 'OVERVIEW_TODOS_INFO',
  OVERVIEW_TODOS_INIT: 'OVERVIEW_TODOS_INIT',
  OVERVIEW_TODOS_FAIL: 'OVERVIEW_TODOS_FAIL',
  GET_SUPPLIER_TABS: 'GET_SUPPLIER_TABS',
}
