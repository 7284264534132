/* Actions */
import * as Actions from '../actionType'

/* Constants */
import { PAGE_TYPE } from '../../../pages/Brand/constants/pageType'
import { brandComponents } from '../../../pages/Brand/constants/brandConstants'

/* Initial State */
export const brandState = {
  currentPage: PAGE_TYPE.ADD,
  visible_component: {
    [brandComponents.brandName]: true,
    [brandComponents.brandDetails]: false,
    [brandComponents.duplicateBrands]: false,
  },
  disable_component: {
    [brandComponents.brandName]: false,
    [brandComponents.brandDetails]: false,
  },
}

export const brandCommonReducer = (state = brandState, action = {}) => {
  const { type, payload, error } = action

  switch (type) {
    case Actions.CHANGE_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: payload,
      }
    }

    case Actions.PURGE_AND_RESET_DATA: {
      return brandState
    }

    case Actions.SET_VISIBLE: {
      return {
        ...state,
        visible_component: {
          ...state.visible_component,
          ...payload,
        },
      }
    }

    case Actions.SET_DISABLE: {
      return {
        ...state,
        disable_component: {
          ...state.disable_component,
          ...payload,
        },
      }
    }

    case Actions.DEPARTMENT_INFO: {
      return {
        ...state,
        departments: payload,
      }
    }

    case Actions.DEPARTMENT_FAIL: {
      return {
        ...state,
        departments: error,
      }
    }

    default:
      return state
  }
}
