export const brandId = {
  id: 'brandId',
  labelText: 'Brand ID',
  filterPlaceholder: 'Brand ID',
}

export const brandName = {
  id: 'brandName',
  labelText: 'Brand Name',
  errorText: 'Error in fetching brands list',
  filterPlaceholder: 'Brand Name',
}

export const brandAbbreviation = {
  id: 'brandAbbreviation',
  labelText: 'Brand Abbreviation',
}

export const brandDescription = {
  id: 'brandDescripiton',
  labelText: 'All about this Brand',
  placeholderText: 'Max 1000 characters',
  maxLength: 1000,
}

export const brandType = {
  id: 'brandType',
  labelText: 'Brand Type',
  options: [
    {
      id: 10103,
      value: 'Target Owned Brand',
      label: 'Target Owned Brand',
    },
  ],
  filterPlaceholder: 'Brand Type',
}

export const brandClassification = {
  id: 'brandClassification',
  labelText: 'Brand Classification',
  options: [
    {
      id: 10305,
      value: 'Target Owned Brand',
      label: 'Target Owned Brand',
    },
    {
      id: 200120,
      value: 'LTO',
      label: 'LTO',
    },
    {
      id: 200119,
      value: 'No Brand',
      label: 'No Brand',
    },
  ],
  filterPlaceholder: 'Brand Classification',
}

export const brandDivision = {
  id: 'brandDivision',
  labelText: 'Brand Division',
  filterPlaceholder: 'Brand Division',
}

export const brandDepartment = {
  id: 'brandDepartment',
  labelText: 'Brand Department',
  filterPlaceholder: 'Brand Department',
}

export const brandStatus = {
  id: 'brandStatus',
  labelText: 'Brand Status',
  filterPlaceholder: 'Brand Status',
  options: [
    {
      id: 1,
      value: 'Approved',
      label: 'Approved',
    },
  ],
}

export const brandRequestedBy = {
  id: 'brandRequestedBy',
  labelText: 'Requested By',
  hintText: 'Please enter email address',
  placeholderText: 'Enter Email ID',
}

export const brandOwnerTarget = {
  id: 'brandOwnerTarget',
  labelText: 'Is this brand owned by Target?',
  options: [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
      disabled: true,
    },
  ],
}

export const brandExclusiveTarget = {
  id: 'brandExclusiveTarget',
  labelText: 'Is this brand Exclusive to Target?',
  options: [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ],
}

export const brandRoyaltyPay = {
  id: 'brandRoyaltyPay',
  labelText: 'Will Target pay Royalty on this Brand?',
  options: [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ],
}

export const brandPersonality = {
  id: 'brandPersonality',
  labelText: 'Is this Brand a personality/character?',
  options: [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ],
}

export const brandProperty = {
  id: 'brandProperty',
  labelText: 'Is this Brand a Property?',
  options: [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ],
}

export const addBrand = {
  id: 'addBrand',
  label: 'Save',
}

export const cancelBrand = {
  id: 'cancelBrand',
  label: 'Cancel',
}

export const backButton = {
  id: 'back',
  label: 'Back',
}

export const editBrand = {
  id: 'editBrand',
  label: 'Save',
}

export const brandQuestions = [
  'owned_by_supplier',
  'owned_by_target',
  'royalty_pay_by_target',
  'exclusive_to_target',
  'publish_to_target',
  'property',
  'personality_or_character',
]

export const brandComponents = {
  brandName: 'brandName',
  brandDetails: 'brandDetails',
  duplicateBrands: 'duplicateBrands',
}

export const apiParams = {
  searchCountPerPage: '10',
}

export const brandConstants = {
  debounceTime: 300,
}

export const filterConstants = {
  defaultPageSize: 10,
  intialPage: 0,
}

export const brandAttributes = [
  'brandOwnerTarget',
  'brandType',
  'brandClassification',
  'brandDivision',
  'brandDepartment',
  'brandRequestedBy',
  'brandDescripiton',
  'brandExclusiveTarget',
  'brandRoyaltyPay',
  'brandPersonality',
  'brandProperty',
]
