/* 3P Dependency */

import { useNavigate } from 'react-router-dom'

/* Component Dependency - Canvas */
import { Grid, Heading, Button } from '@enterprise-ui/canvas-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

/* Constants */

const PageHeader = ({ title, onClose }) => {
  const history = useNavigate()

  const handleClose = () => {
    title === 'Assign Roles' ? history(-1) : onClose ? onClose() : history(-1)
  }

  return (
    <header>
      <Grid.Container spacing={false}>
        <Grid.Item xs={12} className="hc-clr-contrast-weak vmm_pos_rel">
          <Heading
            size={1}
            className="hc-fs-subheading hc-bg-contrast hc-pb-normal hc-pt-normal hc-ta-center fullscreen-header"
          >
            {title}
          </Heading>

          <Button
            type="primary"
            iconOnly
            className="hc-mt-dense vmm__fullscreen__button"
            onClick={handleClose}
            aria-label="Close and go back"
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Grid.Item>
      </Grid.Container>
    </header>
  )
}

export default PageHeader
