import { BrowserRouter as Router } from 'react-router-dom'
// import { createBrowserHistory } from 'history'
// import { unstable_HistoryRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { AuthProvider } from '@praxis/component-auth'
import { AnalyticsProvider } from '@praxis/component-analytics'

import apiConfig from './config/apiConfig'
import Layout from './components/Layout/Layout'
import ScrollToTop from './utills/ScrollToTop'
import configureStore from './store/configureStore'

import { HelveticaForTarget } from '@enterprise-ui/component-font'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import '@enterprise-ui/canvas-ui-css-datepicker'
import './style/index.css'

export const store = configureStore()

const eventManagerConfiguration = {
  ...apiConfig.analytics,
  apiKey: apiConfig.key?.api,
}

const App = () => {
  // const history = createBrowserHistory()
  return (
    <Provider store={store}>
      <ToastProvider location="top" style={{ zIndex: 10 }}>
        <AuthProvider {...apiConfig.auth}>
          <Router>
            <AnalyticsProvider
              eventManagerConfiguration={eventManagerConfiguration}
              trackLocations
            >
              <HelveticaForTarget />
              <ScrollToTop />
              <Layout darkMode={false} theme={'default'} />
            </AnalyticsProvider>
          </Router>
        </AuthProvider>
      </ToastProvider>
    </Provider>
  )
}
export default App
