import merge from 'lodash/merge'
import { config as analyticsConfig } from '@praxis/component-analytics'
import { envInternalConfigs } from './apiConfigInternal'
import { isTarget } from '../utills/Common'

const commonConfig = {
  applicationName: 'Supplier Management',
  auth: {
    authorizationUrl:
      'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/we2w/1',
    logoutUrl: `https://oauth.iam.perf.partnersonline.com/login/responses/logoff.html?TARGET=${window.location.href}`,
    hideLoadingIndicator: false,
  },
  analytics: {
    appName: 'Supplier Management',
    url: analyticsConfig.api.external.stage,
    eventManagerKey: 'VM-2.0-UI',
    retries: 1,
    persistMethod: 'localStorage',
  },
}
// Todo External and internal Urls has to be Separated

const envConfigs = {
  // dev is the default development environment set by .env.development and the default pipeline in .vela.yml
  dev: {
    auth: {
      clientId: 'vmm_npe_im',
    },
    api: {
      location: 'https://vm2location.dev.target.com/location/v1/',
      supplier: 'https://supplier.dev.target.com/supplier/v1/',
      aggregator: 'https://vm2aggregator.dev.target.com/aggregator/v1/',
      contact: 'https://contact.dev.target.com/contact/v1/',
      masterData: 'https://masterdata.dev.target.com/masterdata/v1/',
      validator: 'https://validator.dev.target.com/validator/v1/',
      cms: 'https://externalapi.dev.target.com/external/v1/cms_content/',
      external: 'https://externalapi.dev.target.com/external/v1/',
      brand: 'https://brand.dev.target.com/brand/v1/',
    },
    key: {
      api: '9a2e0c55470af9bd2fd064d2c77ab594d6a6d8a3',
      bing: 'Anw6CzQLWCL5t3ktNtJgpY3xq1L2JNgLMyw7Aqp7VwKU8hwYBAh5SV9P8gLfQ6Iz',
    },
  },
  stg: {
    auth: {
      clientId: 'vmm_npe_im',
    },
    api: {
      location: `https://stage-api.target.com/supplier_management_locations/v1/`,
      supplier: `https://stage-api.target.com/supplier_management_suppliers/v1/`,
      aggregator: `https://stage-api.target.com/supplier_management_aggregators/v1/`,
      contact: `https://stage-api.target.com/supplier_management_contacts/v1/`,
      masterData: `https://stage-api.target.com/supplier_management_masterdata/v1/`,
      validator: `https://stage-api.target.com/supplier_management_validations/v1/`,
      cms: `https://stage-api.target.com/supplier_management_externals/v1/cms_content/`,
      external: `https://stage-api.target.com/supplier_management_externals/v1/`,
      brand: 'https://brand.dev.target.com/brand/v1/',
    },
    key: {
      api: '9a2e0c55470af9bd2fd064d2c77ab594d6a6d8a3',
      bing: 'Anw6CzQLWCL5t3ktNtJgpY3xq1L2JNgLMyw7Aqp7VwKU8hwYBAh5SV9P8gLfQ6Iz',
    },
  },
  prod: {
    auth: {
      authorizationUrl:
        'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/we2w/1',
      logoutUrl: `https://oauth.iam.partnersonline.com/login/responses/logoff.html?TARGET=${window.location.href}`,
      clientId: 'vmm_prod_im',
    },
    analytics: {
      url: analyticsConfig.api.external.prod,
    },
    api: {
      location: `https://api.target.com/supplier_management_locations/v1/`,
      supplier: `https://api.target.com/supplier_management_suppliers/v1/`,
      aggregator: `https://api.target.com/supplier_management_aggregators/v1/`,
      contact: `https://api.target.com/supplier_management_contacts/v1/`,
      masterData: `https://api.target.com/supplier_management_masterdata/v1/`,
      validator: `https://api.target.com/supplier_management_validations/v1/`,
      cms: `https://api.target.com/supplier_management_externals/v1/cms_content/`,
      external: `https://api.target.com/supplier_management_externals/v1/`,
    },
    key: {
      api: 'a639a535dfe888185cddc6a698295670bd4ac9fc',
      bing: 'Anw6CzQLWCL5t3ktNtJgpY3xq1L2JNgLMyw7Aqp7VwKU8hwYBAh5SV9P8gLfQ6Iz',
    },
  },
}

const appEnv = process.env.REACT_APP_ENV
const config = envConfigs[appEnv]

let apiConfig = merge(commonConfig, config)

if (isTarget()) {
  apiConfig = merge(apiConfig, envInternalConfigs[appEnv])
}

export default apiConfig
