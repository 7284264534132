import { useContext } from 'react'
import {
  Card,
  Grid,
  GridContainer,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import { AgGridReact } from '../../../../components/common/Table/AgGrid'
import 'ag-grid-community/styles/ag-grid.css'
import '../../../../components/common/Table/AgGridImports'
import { usePendingLocation } from './usePendingLocation'
import GridHead from './GridHead'
import Filters from './Filters'
import { ThemeContext } from '../../../../components/Layout/Layout'

const PendingLocation = (props) => {
  const {
    data,
    loading,
    error,
    gridOptions,
    rowCount,
    selectType,
    onQuickFilterText,
    selectFilter,
    exportCsv,
  } = usePendingLocation(props)

  const { current } = gridOptions
  const isDarkMode = useContext(ThemeContext)
  const rowCnt =
    current && current?.api
      ? current?.api?.api.getDisplayedRowCount()
      : rowCount

  if (error) {
    return (
      <div className="hc-pa-normal hc-bg-grey07 vm-no-padding-mobile">
        <Card style={{ minHeight: '80vh' }}>
          <div className="hc-ml-normal hc-pb-expanded">
            Something went wrong ...
            <Spinner size="dense" className="hc-ml-normal" />
          </div>
        </Card>
      </div>
    )
  }
  if (loading) {
    return (
      <div className="hc-pa-normal hc-bg-grey07 vm-no-padding-mobile">
        <Card style={{ minHeight: '80vh' }}>
          <div className="hc-ml-normal hc-pb-expanded">
            Loading suppliers location data
            <Spinner size="dense" className="hc-ml-normal" />
          </div>
        </Card>
      </div>
    )
  }
  if (data?.length > 0) {
    return (
      <div className="hc-pa-normal hc-bg-grey07 vm-no-padding-mobile">
        <GridContainer>
          <GridHead rowCnt={rowCnt} exportCsv={exportCsv} />
          <Grid.Item xs={12} className="hc-pb-none">
            <Card style={{ minHeight: '78vh' }}>
              <GridContainer>
                <Filters
                  selectType={selectType}
                  selectFilter={selectFilter}
                  onQuickFilterText={onQuickFilterText}
                  data={data}
                />
                <Grid.Item xs={12} className="hc-pt-none hc-pb-none">
                  <div
                    id="supplier-pending-location-Grid"
                    className={`vm-ag-grid-container ${
                      isDarkMode ? 'ag-theme-balham-dark' : 'ag-theme-balham'
                    }`}
                    style={{ minHeight: '78vh' }}
                  >
                    <AgGridReact gridOptions={current} />
                  </div>
                </Grid.Item>
              </GridContainer>
            </Card>
          </Grid.Item>
        </GridContainer>
      </div>
    )
  } else {
    return (
      <div className="hc-pa-normal hc-bg-grey06 hc-pt-none">
        <Card
          style={{ minHeight: '80vh', justifyContent: 'center' }}
          className="vmm_flex_center hc-ta-center"
        >
          <div className="hc-ml-normal hc-pb-expanded">
            Pending locations not found
          </div>
        </Card>
      </div>
    )
  }
}
export default PendingLocation
