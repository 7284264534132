export const FETCH_CONTACT_DATA = 'FETCH_CONTACT_DATA'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'

export const PURGE_AND_RESET_DATA = 'PURGE_AND_RESET_DATA'

export const SAVE_USER_INFO = 'SAVE_USER_INFO'

export const NORMALIZE_SUPPLIER_DATA = 'NORMALIZE_SUPPLIER_DATA'

export const UPDATE_SUPPLIER_TYPE = 'UPDATE_SUPPLIER_TYPE'

export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT'

export const SET_COMPANY_ADMIN = 'SET_COMPANY_ADMIN'

export const SET_POL_SYSTEM_ACCESS = 'SET_POL_SYSTEM_ACCESS'

export const SET_NOTIFY_USER_LABEL = 'SET_NOTIFY_USER_LABEL'

export const UPDATE_APPS_AND_REPORT = 'UPDATE_APP_AND_REPORT'

export const UPDATE_RESPONSIBILITY = 'UPDATE_RESPONSIBILITY'

export const SAVE_POST_API_DATA = 'SAVE_POST_API_DATA'
