/* Actions */
import * as Actions from '../actionType'

/* Initial State */
const initialState = {}

export const apiDataReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case Actions.FETCH_COUNTRY_LIST: {
      return {
        ...state,
        countryList: payload,
      }
    }

    case Actions.FETCH_CITY_STATE_BY_ZIPCODE: {
      return {
        ...state,
        cityStateByZipcode: payload.cityStateByZipcode,
        jobToken: payload.jobToken,
      }
    }

    case Actions.FETCH_ADDRESS_FORMAT:
    case Actions.FETCH_STATES_LIST: {
      const { countryCode, ..._data } = payload

      return {
        ...state,
        [countryCode]: {
          ...state[countryCode],
          ..._data,
        },
      }
    }

    case Actions.FETCH_EXIST_DUP_ADDRESS_AND_VALIDATE: {
      return {
        ...state,
        duplicateAddress: payload.potential_locations,
        informaticaValidation: payload.location_message || {},
        validatedLocation: payload.validated_location,
        existingLocation: payload.exact_location || {},
      }
    }

    case Actions.RESET_EXIST_DUP_ADDRESS_AND_VALIDATE: {
      const updatedState = { ...state }
      delete updatedState.duplicateAddress
      delete updatedState.informaticaValidation
      delete updatedState.validatedLocation
      delete updatedState.existingLocation

      return updatedState
    }

    case Actions.FETCH_LOCATION_TYPES: {
      return {
        ...state,
        locationType: payload,
      }
    }

    case Actions.FETCH_LOCATION_CAPABILITY: {
      return {
        ...state,
        locationCapability: payload,
      }
    }

    case Actions.SAVE_ADD_DETAILS_SUCCESS: {
      return {
        ...state,
        saveAndAdd: payload,
      }
    }

    case Actions.FETCH_CARRIER_DETAILS: {
      return {
        ...state,
        carrierDetails: payload,
      }
    }

    case Actions.FETCH_TIMEZONE: {
      return {
        ...state,
        timeZone: payload,
      }
    }

    case Actions.FETCH_HOLIDAY_LIST: {
      return {
        ...state,
        holidays: payload,
      }
    }

    case Actions.FETCH_TRANSIT_HOURS: {
      return {
        ...state,
        transitHours: payload,
      }
    }

    case Actions.FETCH_PORT_OF_EXPORT: {
      return {
        ...state,
        portOfExport: payload,
      }
    }

    case Actions.FETCH_LOCATION_CONTACTS: {
      return {
        ...state,
        locationContacts: payload,
      }
    }

    case Actions.FETCH_LOCATION_READY_CHECKLIST: {
      return {
        ...state,
        locationReadyChecklist: payload,
      }
    }

    case Actions.SAVE_LOCATION_DETAILS: {
      return {
        ...state,
        locationDetails: payload,
      }
    }

    case Actions.SAVE_LOCATION_SUMMARY: {
      return {
        ...state,
        locationSummary: payload,
      }
    }

    case Actions.SAVE_SUPPLIER_DETAILS: {
      return {
        ...state,
        supplierDetails: payload,
      }
    }

    case Actions.FETCH_SHIP_TERMS: {
      return {
        ...state,
        shipTerms: payload,
      }
    }

    case Actions.FETCH_LOCATION_TYPE_CAPABILITY_BY_ID: {
      return {
        ...state,
        locationTypeCapabilityById: payload,
      }
    }

    case Actions.FETCH_SHIPPING_DETAILS: {
      return {
        ...state,
        shippingDetails: payload,
      }
    }

    case Actions.FETCH_SIMILAR_LOCATIONS: {
      return {
        ...state,
        similarLocations: payload,
      }
    }

    case Actions.FETCH_REASON_FOR_EDIT: {
      return {
        ...state,
        reasonForEdit: payload,
      }
    }

    case Actions.FETCH_IMPORT_DETAILS: {
      return {
        ...state,
        importDetails: payload,
      }
    }

    case Actions.FETCH_TRADE_PROGRAM_TYPES: {
      return {
        ...state,
        tradeProgramTypes: payload,
      }
    }

    case Actions.FETCH_REVIEW_ADDRESS: {
      return {
        ...state,
        reviewAddress: payload,
      }
    }

    case Actions.FETCH_SECURITY_DETAILS: {
      return {
        ...state,
        securityDetails: payload,
      }
    }

    case Actions.RESET_API_DATA_EXCEPT_COUNTRY: {
      return {
        ...initialState,
        countryList: state.countryList,
      }
    }

    case Actions.RESET_API_DATA: {
      return initialState
    }

    default:
      return state
  }
}
