import { checkUser } from '../../utills/Common'
import { useAuth } from '@praxis/component-auth'

export const AccessGroups = (props) => {
  const auth = useAuth()
  const { session } = auth || {}
  const userInfo = checkUser(session)
  const { adGroups } = userInfo

  const securityUser = ['APP-VM-SECURITY']
  const sysAdminUser = ['APP-VM-SYSADMIN']
  // const viewOnlyUser = ['APP-VM-VIEW']
  const updateUser = ['APP-VM-UPDATE']
  const approveUser = ['APP-VM-APPROVE ']
  // const mvsUser = ['APP-VM-MVS']
  /*
    const isViewOnlyUser = (membership, group) => {
      return membership.includes(group)
    }
    const isUpdateUser = (membership, group) => {
      return membership.includes(group)
    }
    const isApproveUser = (membership, group) => {
      return membership.includes(group)
    }
    const isMbsUser = (membership, group) => {
      return membership.includes(group)
    }
    const isSecurityUser = (membership, group) => {
      return membership.includes(group)
    }
    const accessUser = (membership, group) => {
      return membership.includes(group)
    }
  */
  const accessUser = () => {
    const allowedGroup = [sysAdminUser, securityUser]
    const userGroup = adGroups?.filter((val) => allowedGroup.includes(val))
    return userGroup?.length > 0
  }
  const accessUser1 = () => {
    const allowedGroup = [sysAdminUser, securityUser, updateUser, approveUser]
    const userGroup = adGroups?.filter((val) => allowedGroup.includes(val))
    return userGroup.length > 0
  }
  const isPartnersOnline = () => {
    const url = window.location.href
    return /(^([^/]+:)?\/{2,3}[^/]+?(\.partnersonline\.com)(:|\/|$))/i.test(url)
  }

  const userInfoDetails = () => {
    let memberOf,
      user,
      companyName,
      isUser = true
    if (session) {
      const business_category = session?.identity?.business_category
      memberOf = session?.identity?.memberof
      companyName = session?.identity?.company
      // isUser = business_category === '' || business_category === 'T'
      isUser = business_category === 'E'
    }
    user = { memberOf: memberOf, isUser: isUser, companyName: companyName }
    return user
  }

  const userDetails = () => {
    return userInfo
  }

  const accessDetails = userDetails()

  return {
    accessDetails,
    accessUser1,
    accessUser,
    userInfoDetails,
    isPartnersOnline,
  }
}

/**
 APP-VM-VIEW     : Provides view only internal
 APP-VM-UPDATE   : Add/delete a dept, invite a BP, location be added/edited/deleted, request a contact
 APP-VM-APPROVE  : Authorize shipping locations,  authorize SLA  approve & exit and archives PTs & above
 APP-VM-SECURITY : VMM Settings and everything that SECURITY, APPROVE & UPDATE can do, Add a contact, MPL (POL Agreements)
 APP-VM-SYSADMIN : VMM Settings and everything that SECURITY, APPROVE & UPDATE can do, Add a contact, MPL (POL Agreements)
 APP-VM-PAYMENT  :
 APP-VM-PARTNERVIEW   :
 APP-VM-PARTNERUPDATE :
 APP-VM-PARTNERADMIN  :
 APP-VM-PARTNERPAYMENT:
 APP-VM-GENERIC : Generic view only access to VM2
 APP-VM-MVS     : Access to MVS application from VM2
 **/
/*
const accessUser = [
  'APP-VM-UPDATE',
  'APP-VM-APPROVE',
  'APP-VM-SECURITY',
  'APP-VM-SYSADMIN',
]
const externalUser = [
  'APP-VMM-PARTNER',
  'APP-VMM-PARTNERUPDATE',
  'APP-VMM-PARTNERADMIN',
]

const internalUser = {
  '': 'READ_ONLY',
  'APP-VM-VIEW': 'READ_ONLY',
}
*/

/*
External : APP-VMM-PARTNER,APP-VMM-PARTNERUPDATE,APP-VMM-PARTNERADMIN
Internal : APP-VMM,APP-VMM-UPDATE,APP-VMM-SYSADMIN,APP-VMM-LTDADMIN
const roleTable = {
  'APP-OAUTH2-TVI-FINOPS-NPE': 'ROLES_FINANCE',
  'APP-OAUTH2-TVI-INQUIRE-NPE': 'ROLES_INQUIRE',
  'APP-OAUTH2-TVI-MERCH-NPE': 'ROLES_MERCH',
  'APP-OAUTH2-TVI-SUPPORT-NPE': 'ROLES_SUPPORT',
  'APP-OAUTH2-TVI-VENDOR-NPE': 'ROLES_VENDOR',
  'APP-OAUTH2-TVI-ADMIN-NPE': 'ROLES_ADMIN',
  'APP-OAUTH2-TVI-VENDOR': 'ROLES_VENDOR',
}
 */
