import apiConfig from '../../../config/apiConfig'
import Cookie from '../../../utills/useCookies'
import { useFirefly } from '../../../utills/useAnalytics'

export const useHeader = (auth) => {
  const { login, logout } = auth
  const options = ['Logout']
  const { handleFireflyClickEvent } = useFirefly()

  const logOutRedirect = () => {
    // Todo move to Auth controller
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('X-USER-TOKEN')
    localStorage.removeItem('supplier_info')
    Cookie.delete('SSOSESSIONINFO', { path: '', domain: '.partnersonline.com' })
    logout()
    window.location.href = `${apiConfig.auth.logoutUrl}`
  }
  const handleClose = (event) => {
    if (event.target.textContent) {
      handleFireflyClickEvent(event.target.textContent)
      switch (event.target.textContent) {
        case 'Logout':
          logOutRedirect()
          break
        case 'Login':
          login()
          break
        default:
          return
      }
    }
  }
  return {
    options,
    handleClose,
    logOutRedirect,
  }
}
