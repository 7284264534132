import { useNavigate } from 'react-router-dom'
import { useAnalytics } from '@praxis/component-analytics'
import { useRef, useState, useCallback } from 'react'

const domestic = ['US', 'CA']

export const useGridCommon = (props) => {
  const history = useNavigate()
  const { trackClick } = useAnalytics()
  const query = useRef(null)
  const queryVal = useRef(null)
  const [rowCount, setRowCount] = useState(0)
  const [rowSelected, setRowSelected] = useState([])
  const [rowIndex, setRowIndex] = useState(0)
  const [selectType, setSelectType] = useState({
    terms: 'any',
    location: 'any',
    reason: 'any',
  })
  let defaultShipTerms = [
    {
      label: 'Any Terms',
      value: 'any',
    },
  ]
  let defaultReason = [
    {
      label: 'Any Reason',
      value: 'any',
    },
  ]
  const pluralize = (count, noun, suffix = 's') =>
    `${count} ${noun}${count > 1 ? suffix : ''}`

  const capitalize = ([first, ...rest]) =>
    first.toUpperCase() + rest.join('').toLowerCase()

  const navigateTo = (event, url) => {
    event.preventDefault()
    if (url) {
      trackClick(event.target.textContent)

      history(url)
    }
  }

  const setFilterData = useCallback((filterData, key, defaultFilter) => {
    const filterTypes = {}

    filterData?.forEach((shipLoc) => {
      if (key === 'Reason') {
        const { review = {} } = shipLoc
        const reason = review?.reason_for_edit
          ? review?.reason_for_edit
          : review?.update_type

        if (!reason) return

        const splitReason = reason?.split(',')

        if (splitReason?.length > 1) {
          splitReason.forEach((item) => (filterTypes[item] = item))
        } else {
          filterTypes[reason] = reason
        }

        return
      }

      if (key === 'Terms') {
        const { ship_terms = {} } = shipLoc

        if (ship_terms?.length) {
          ship_terms.forEach((term) => {
            const termName = term?.ship_term_name
            if (termName) {
              filterTypes[termName] = termName
            }
          })
        }

        return
      }
    })

    const finalTypes = Object.keys(filterTypes)?.map((type) => {
      const trimedType = type.trim()
      return {
        value: trimedType,
        label: capitalize(trimedType),
      }
    })

    return defaultFilter.concat(finalTypes)
  }, [])

  const doesExternalFilterPass = useCallback((node) => {
    if (queryVal.current !== 'any') {
      let data, value, filterData, filterTerms
      value = queryVal.current !== 'any' ? queryVal.current : ''

      switch (query.current) {
        case 'Terms':
          filterTerms = node.data?.ship_terms?.filter((obj) => {
            return obj?.ship_term_name === value
          })
          filterData = filterTerms
          break

        case 'Location':
          data = node.data.address.country_code
          filterData =
            value === 'domestic'
              ? domestic.indexOf(data) > -1
              : domestic.indexOf(data) === -1

          return filterData
        case 'Reason':
          data = node.data.review?.reason_for_edit
            ? node.data.review?.reason_for_edit
            : node.data.review?.update_type
          filterTerms = (str) =>
            data
              ?.split(',')
              .filter((item) =>
                new RegExp('^' + str.replace(/\*/g, '.*') + '$').test(
                  item.trim()
                )
              )
          filterData = filterTerms(value)
          break
        default:
          break
      }
      return filterData?.length > 0
    } else {
      return true
    }
  }, [])

  return {
    query,
    queryVal,
    selectType,
    setSelectType,
    rowCount,
    setRowCount,
    rowSelected,
    setRowSelected,
    rowIndex,
    setRowIndex,
    defaultShipTerms,
    defaultReason,
    pluralize,
    capitalize,
    navigateTo,
    setFilterData,
    doesExternalFilterPass,
  }
}
