import { useRef } from 'react'
import { useEffect } from 'react'
import { useGrid } from '../../../../utills/Grid/useAgGrid'
import { GridOptions } from './GridOptions'
import { ColumnDefs } from './ColumDefs'
import { useGridCommon } from '../useGridCommon'
import { useLocationFetch } from '../useLocationFetch'

export const usePendingLocation = (props) => {
  const {
    navigateTo,
    doesExternalFilterPass,
    query,
    queryVal,
    selectType,
    setSelectType,
    rowCount,
    setRowCount,
    rowSelected,
    setRowSelected,
    rowIndex,
    setRowIndex,
  } = useGridCommon()

  const { data, error, loading } = useLocationFetch(props.filterType)

  /********* Start AG Grid Functions **************/
  const onQuickFilterText = (event) => {
    getGridApi().setGridOption('quickFilterText', event.target.value)
    setRowCount(getGridApi().getDisplayedRowCount())
  }
  const selectFilter = (id, value) => {
    if (id === 'Terms') {
      setSelectType({
        ...selectType,
        location: 'any',
        reason: 'any',
        terms: value,
      })
    } else if (id === 'Location') {
      setSelectType({
        ...selectType,
        terms: 'any',
        reason: 'any',
        location: value,
      })
    } else if (id === 'Reason') {
      setSelectType({
        ...selectType,
        terms: 'any',
        location: 'any',
        reason: value,
      })
    }
    query.current = id
    queryVal.current = value
    getGridApi() && getGridApi().onFilterChanged()
    setRowCount(getGridApi().getDisplayedRowCount())
  }

  const exportCsv = (e) => {
    let params = {
      fileName: 'SM_LOCATION_PENDING',
      sheetName: 'locations',
      exportMode: true,
      onlySelected: rowSelected && rowSelected.length > 0,
    }
    getGridApi().exportDataAsCsv(params)
  }

  const onSelectionChanged = () => {
    // setRowSelected(getGridApi().getSelectedRows())
  }

  const onCellClicked = (params) => {
    const isDelete = params.column.colDef.isDelete
    if (isDelete) {
      const data = params.node.data
      setRowSelected([data])
      getGridApi().selectNode(params.node, true)
      setRowIndex(params.rowIndex)
    }
  }

  /********* End AG Grid Functions **************/

  /********* Start AG Grid Configs **************/
  const { gridReady, onGridReady, getGridApi } = useGrid()
  const gridOptions = useRef(GridOptions)
  const gridData = data

  gridOptions.current = {
    ...gridOptions.current,
    onGridReady: onGridReady,
    isExternalFilterPresent: () => {
      return !!query.current
    },
    doesExternalFilterPass: doesExternalFilterPass,
    onSelectionChanged: onSelectionChanged,
    onCellClicked: onCellClicked,
    onGridSizeChanged: () => {
      getGridApi().sizeColumnsToFit()
    },
    tabToNextCell: () => {
      getGridApi().startEditingCell({
        rowIndex: 0,
        rowPinned: null,
        keyPress: null,
        charPress: null,
      })
    },
  }
  const closeDeleteModal = () => {
    getGridApi().getRowNode(rowIndex).setSelected(false)
  }
  useEffect(() => {
    if (gridReady) {
      getGridApi().setGridOption('columnDefs', ColumnDefs)
      getGridApi().sizeColumnsToFit()
      getGridApi().setGridOption('rowData', gridData)
      setRowCount(getGridApi().getDisplayedRowCount())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridReady, gridData, getGridApi()?.getDisplayedRowCount()])

  /********* End AG Grid Configs **************/

  return {
    gridOptions,
    rowCount,
    rowSelected,
    selectType,
    data,
    error,
    loading,
    onQuickFilterText,
    selectFilter,
    navigateTo,
    exportCsv,
    closeDeleteModal,
  }
}
