export const TYPE = {
  SUPPLIER_PROFILE_INFO: 'SUPPLIER_PROFILE_INFO',
  SUPPLIER_PROFILE_INIT: 'SUPPLIER_PROFILE_INIT',
  SUPPLIER_PROFILE_FAIL: 'SUPPLIER_PROFILE_FAIL',
  SUPPLIER_LOCATION_INFO: 'SUPPLIER_LOCATION_INFO',
  SUPPLIER_LOCATION_INIT: 'SUPPLIER_LOCATION_INIT',
  SUPPLIER_LOCATION_FAIL: 'SUPPLIER_LOCATION_FAIL',
  SUPPLIER_CONTACT_INFO: 'SUPPLIER_CONTACT_INFO',
  SUPPLIER_CONTACT_INIT: 'SUPPLIER_CONTACT_INIT',
  SUPPLIER_CONTACT_FAIL: 'SUPPLIER_CONTACT_FAIL',
  SUPPLIER_CONTACT_REVIEW_INFO: 'SUPPLIER_CONTACT_REVIEW_INFO',
  SUPPLIER_CONTACT_REVIEW_INIT: 'SUPPLIER_CONTACT_REVIEW_INIT',
  SUPPLIER_CONTACT_REVIEW_FAIL: 'SUPPLIER_CONTACT_REVIEW_FAIL',
  SUPPLIER_CONTACT_DELETE: 'SUPPLIER_CONTACT_DELETE',
  SUPPLIER_INVITE_INFO: 'SUPPLIER_INVITE_INFO',
  SUPPLIER_INVITE_INIT: 'SUPPLIER_INVITE_INIT',
  SUPPLIER_INVITE_FAIL: 'SUPPLIER_INVITE_FAIL',
  SUPPLIER_POST_INVITE_INFO: 'SUPPLIER_POST_INVITE_INFO',
  SUPPLIER_POST_INVITE_INIT: 'SUPPLIER_POST_INVITE_INIT',
  SUPPLIER_POST_INVITE_FAIL: 'SUPPLIER_POST_INVITE_FAIL',
  LOCATION_SEARCH_OPTIONS_INFO: 'LOCATION_SEARCH_OPTIONS_INFO',
  LOCATION_SEARCH_OPTIONS_RESET: 'LOCATION_SEARCH_OPTIONS_RESET',
  SUPPLIER_USER_INVITE_INFO: 'SUPPLIER_USER_INVITE_INFO',
  SUPPLIER_USER_INVITE_INIT: 'SUPPLIER_USER_INVITE_INIT',
  SUPPLIER_USER_INVITE_FAIL: 'SUPPLIER_USER_INVITE_FAIL',
  SUPPLIER_INVITE_LIST_INFO: 'SUPPLIER_INVITE_LIST_INFO',
  SUPPLIER_INVITE_LIST_INIT: 'SUPPLIER_INVITE_LIST_INIT',
  SUPPLIER_INVITE_LIST_FAIL: 'SUPPLIER_INVITE_LIST_FAIL',
  SUPPLIER_INVITE_LIST_RESET: 'SUPPLIER_INVITE_LIST_RESET',
  SUPPLIER_LIST_INFO: 'SUPPLIER_LIST_INFO',
  SUPPLIER_LIST_INIT: 'SUPPLIER_LIST_INIT',
  SUPPLIER_LIST_FAIL: 'SUPPLIER_LIST_FAIL',
  REVIEW_LOCATION_INFO: 'REVIEW_LOCATION_INFO',
  REVIEW_LOCATION_INIT: 'REVIEW_LOCATION_INIT',
  REVIEW_LOCATION_FAIL: 'REVIEW_LOCATION_FAIL',
  EDIT_DUNS_INFO: 'EDIT_DUNS_INFO',
  EDIT_DUNS_INIT: 'EDIT_DUNS_INIT',
  EDIT_DUNS_FAIL: 'EDIT_DUNS_FAIL',
  BRAND_LIST_INIT: 'BRAND_LIST_INIT',
  BRAND_LIST_INFO: 'BRAND_LIST_INFO',
  BRAND_LIST_FAIL: 'BRAND_LIST_FAIL',
  BRAND_ID_FILTER: 'BRAND_ID_FILTER',
  BRAND_NAME_FILTER: 'BRAND_NAME_FILTER',
  BRAND_TYPE_FILTER: 'BRAND_TYPE_FILTER',
  BRAND_CLASSIFICATION_FILTER: 'BRAND_CLASSIFICATION_FILTER',
  BRAND_STATUS_FILTER: 'BRAND_STATUS_FILTER',
  BRAND_PAGE_SIZE: 'BRAND_PAGE_SIZE',
  BRAND_PAGE_NUMBER: 'BRAND_PAGE_NUMBER',
  CLEAR_BRAND_FILTER: 'CLEAR_BRAND_FILTER',
}
