/**
 * @enum
 * List of all the page types
 */

export const PAGE_TYPE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  SAVED: 'SAVED',
}
