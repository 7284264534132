import { config as analyticsConfig } from '@praxis/component-analytics'

export const envInternalConfigs = {
  dev: {
    auth: {
      authorizationUrl:
        'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
      logoutUrl:
        'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    },
    analytics: {
      url: analyticsConfig.api.internal.stage,
    },
  },
  stg: {
    auth: {
      authorizationUrl:
        'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
      logoutUrl:
        'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    },
    analytics: {
      url: analyticsConfig.api.internal.stage,
    },
  },
  prod: {
    auth: {
      authorizationUrl: 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      logoutUrl:
        'https://logonservices.iam.target.com/login/responses/logoff.html',
    },
    analytics: {
      url: analyticsConfig.api.internal.prod,
    },
  },
}
