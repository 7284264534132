import * as ACTIONS from './actionType'

export const supplierInfoState = {
  targetSetUp: {
    data: [],
    loading: null,
    error: '',
  },
  locationInfo: {
    data: [],
    loading: false,
    error: null,
  },
  contactInfo: {
    data: [],
    loading: false,
    error: '',
  },
  relationshipInfo: {
    data: null,
    loading: false,
    error: '',
  },
}

export default function supplier(state = supplierInfoState, action = {}) {
  switch (action.type) {
    case ACTIONS.TYPE.TARGET_SETUP_INIT: {
      let setup = {
        ...state,
        targetSetUp: {
          loading: true,
          error: '',
        },
      }
      return setup
    }
    case ACTIONS.TYPE.TARGET_SETUP_INFO: {
      let setup = {
        ...state,
        targetSetUp: {
          data: action.data,
          loading: false,
          error: '',
        },
      }
      return setup
    }
    case ACTIONS.TYPE.TARGET_SETUP_FAIL: {
      let setup = {
        ...state,
        targetSetUp: {
          loading: false,
          error: action.error,
        },
      }
      return setup
    }
    case ACTIONS.TYPE.TARGET_SETUP_RESET: {
      let setup = {
        ...state,
        targetSetUp: {
          data: [],
          loading: null,
          error: '',
        },
      }
      return setup
    }
    case ACTIONS.TYPE.SUPPLIER_LOCATION_INIT: {
      let location = {
        ...state,
        locationInfo: {
          loading: true,
          error: null,
        },
      }
      return location
    }
    case ACTIONS.TYPE.SUPPLIER_LOCATION_INFO: {
      let location = {
        ...state,
        locationInfo: {
          loading: false,
          data: action.data,
        },
      }
      return location
    }
    case ACTIONS.TYPE.SUPPLIER_LOCATION_FAIL: {
      let location = {
        ...state,
        locationInfo: {
          loading: false,
          error: action.payload?.error?.error,
          data: [],
        },
      }
      return location
    }
    case ACTIONS.TYPE.SUPPLIER_CONTACT_INIT: {
      let contact = {
        ...state,
        contactInfo: {
          loading: true,
          error: '',
        },
      }
      return contact
    }
    case ACTIONS.TYPE.SUPPLIER_CONTACT_INFO: {
      let contact = {
        ...state,
        contactInfo: {
          data: action.data,
          loading: false,
          error: '',
        },
      }
      return contact
    }
    case ACTIONS.TYPE.SUPPLIER_CONTACT_FAIL: {
      let contact = {
        ...state,
        contactInfo: {
          loading: false,
          error: action.error,
        },
      }
      return contact
    }
    case ACTIONS.TYPE.SUPPLIER_CONTACT_RESET: {
      let contact = {
        ...state,
        contactInfo: {
          data: [],
          loading: false,
          error: false,
        },
      }
      return contact
    }
    case ACTIONS.TYPE.SUPPLIER_LOCATION_RESET: {
      let location = {
        ...state,
        locationInfo: {
          data: [],
          loading: null,
          error: false,
        },
      }
      return location
    }

    case ACTIONS.TYPE.SUPPLIER_RESET: {
      return supplierInfoState
    }

    case ACTIONS.TYPE.FETCH_ASSIGN_ROLES: {
      return {
        ...state,
        assignedRoles: action.data,
      }
    }

    case ACTIONS.TYPE.UPDATE_ASSIGN_ROLES: {
      let contact = {
        ...state,
        assignedRoles: {
          ...state.assignedRoles,
          responsibilities: action.payload,
        },
      }
      return contact
    }

    case ACTIONS.TYPE.FETCH_SUPPLIER_DOCUMENT: {
      return {
        ...state,
        supplierDocs: action.payload,
      }
    }

    case ACTIONS.TYPE.RESET_SUPPLIER_DOCUMENT: {
      return {
        ...state,
        supplierDocs: null,
      }
    }

    case ACTIONS.TYPE.FETCH_DEPARTMENTS_WITH_NO_SHIPPING_LOCATION_INIT: {
      return {
        ...state,
        deptsWithNoShippingLocation: {
          loading: true,
          error: false,
          data: null,
        },
      }
    }

    case ACTIONS.TYPE.FETCH_DEPARTMENTS_WITH_NO_SHIPPING_LOCATION_INFO: {
      return {
        ...state,
        deptsWithNoShippingLocation: {
          loading: false,
          error: false,
          data: action.data,
        },
      }
    }

    case ACTIONS.TYPE.FETCH_DEPARTMENTS_WITH_NO_SHIPPING_LOCATION_FAIL: {
      return {
        ...state,
        deptsWithNoShippingLocation: {
          loading: false,
          error: true,
          data: null,
        },
      }
    }
    case ACTIONS.TYPE.SUPPLIER_RELATIONSHIP_INIT: {
      let relationship = {
        ...state,
        relationshipInfo: {
          loading: true,
          error: '',
        },
      }
      return relationship
    }
    case ACTIONS.TYPE.SUPPLIER_RELATIONSHIP_INFO: {
      let relationship = {
        ...state,
        relationshipInfo: {
          data: action.data,
          loading: false,
          error: '',
        },
      }
      return relationship
    }
    case ACTIONS.TYPE.SUPPLIER_RELATIONSHIP_FAIL: {
      let relationship = {
        ...state,
        relationshipInfo: {
          loading: false,
          error: action.error,
        },
      }
      return relationship
    }
    case ACTIONS.TYPE.SUPPLIER_RELATIONSHIP_RESET: {
      let relationship = {
        ...state,
        relationshipInfo: {
          data: null,
          loading: null,
          error: false,
        },
      }
      return relationship
    }

    default: {
      return state
    }
  }
}
