/* Actions */
import * as Actions from '../actionType'

/* Initial State */
const initialState = {}

export const userDataReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case Actions.COUNTRY: {
      return {
        ...state,
        address: {
          ...state.address,
          ...payload,
        },
      }
    }

    case Actions.ADDRESS: {
      return {
        ...state,
        address: {
          ...state.address,
          ...payload,
        },
      }
    }

    case Actions.SAVE_DUPLICATE_SELECTED_LOC_ID: {
      return {
        ...state,
        duplicateLocationId: payload?.location_id,
        duplicateLocationName: payload?.location_name,
        duplicateLocationStatus: payload?.location_status,
      }
    }

    case Actions.REMOVE_DUPLICATE_LOC_ID_NAME: {
      return {
        ...state,
        duplicateLocationId: '',
        duplicateLocationName: '',
      }
    }

    case Actions.SAVE_LOCATION_NAME: {
      return {
        ...state,
        location_name: payload,
      }
    }

    case Actions.SAVE_LOCATION_TYPE: {
      return {
        ...state,
        location_types: payload,
      }
    }

    case Actions.SAVE_LOCATION_ID_SUPPLIER_TYPE_MAP: {
      return {
        ...state,
        location_type_id_supplier_type_map: payload,
      }
    }

    case Actions.SAVE_MANUFACTURING_ATTRIBUTES: {
      return {
        ...state,
        manufacturing_attributes: payload,
      }
    }

    case Actions.SAVE_LOCATION_CAPABILITY: {
      return {
        ...state,
        location_capabilities: payload,
      }
    }

    case Actions.SAVE_CARRIER_DETAILS: {
      return {
        ...state,
        dvs_details: {
          ...state.dvs_details,
          carriers: payload,
        },
      }
    }

    case Actions.SAVE_ORDER_CUTOFF_TIME: {
      return {
        ...state,
        dvs_details: {
          ...state.dvs_details,
          order_cut_off_time: {
            ...state.dvs_details?.order_cut_off_time,
            ...payload,
          },
        },
      }
    }

    case Actions.SAVE_GENERAL_ORDER: {
      return {
        ...state,
        general_ordering_info: payload,
      }
    }

    case Actions.SAVE_HOUR_OPERATION: {
      return {
        ...state,
        hour_of_operation: payload,
      }
    }

    case Actions.SAVE_SELECTED_CONTACT: {
      return {
        ...state,
        selectedContact: payload,
      }
    }

    case Actions.VALIDATE_LOCATION_CONTACT: {
      return {
        ...state,
        isValidLocationContact: payload,
      }
    }

    case Actions.SAVE_HOLIDAY_CLOSURE: {
      return {
        ...state,
        holidays: payload,
      }
    }

    case Actions.SAVE_LOCATION_ID: {
      return {
        ...state,
        locationId: payload,
      }
    }

    case Actions.SAVE_SHIP_TERMS: {
      return {
        ...state,
        shipTerms: payload,
      }
    }

    case Actions.SAVE_POE_COUNTRY: {
      return {
        ...state,
        selectedPOECountry: payload,
      }
    }

    case Actions.SAVE_PORT_OF_EXPORT: {
      return {
        ...state,
        selectedPortOfExport: payload,
      }
    }

    case Actions.SAVE_TRANSIT_HOURS: {
      return {
        ...state,
        transit_hours: payload,
      }
    }

    case Actions.SAVE_REASON_FOR_EDIT_ADDRESS: {
      return {
        ...state,
        reason_for_edit: payload,
      }
    }

    case Actions.GEO_CODE_NOT_UPDATED: {
      return {
        ...state,
        isGeoCodeNotUpdated: payload,
      }
    }

    case Actions.SAVE_UPDATED_SIM_LOC: {
      return {
        ...state,
        similarLocations: payload,
      }
    }

    case Actions.ENABLE_EDIT_ADDRESS: {
      return {
        ...state,
        ...payload,
      }
    }

    case Actions.CHANGE_EDIT_SHIPPING_FLAGS: {
      return {
        ...state,
        ...payload,
      }
    }

    case Actions.HOUR_HOLIDAY_CLOSURE_EDITED: {
      return {
        ...state,
        ...payload,
      }
    }

    case Actions.SHOW_EDIT_REVIEW_ADDRESS: {
      return {
        ...state,
        showEditReviewAddress: payload,
      }
    }

    case Actions.RESET_USER_DATA: {
      if (payload) {
        return {
          ...initialState,
          locationId: payload,
        }
      }
      return initialState
    }

    default:
      return state
  }
}
