export const selectionTypes = [
  {
    name: 'all',
    value: 'any',
  },
  {
    name: 'shipping',
    value: 'Shipping',
  },
  {
    name: 'manufacturing',
    value: 'Manufacturing',
  },
]
export const selectionStatus = [
  {
    name: 'Any Status',
    value: 'any',
  },
  {
    name: 'In Progress',
    value: 'In Progress',
  },
  {
    name: 'Under Review',
    value: 'Under Review',
  },
  {
    name: 'Approved',
    value: 'Approved',
  },
  {
    name: 'Not Approved',
    value: 'Not Approved',
  },
]
export const countryTypes = [
  {
    name: 'Domestic & Import',
    value: 'any',
  },
  {
    name: 'Domestic',
    value: 'domestic',
  },
  {
    name: 'Import',
    value: 'international ',
  },
]
export const addLocation = [
  {
    name: 'Ship Location',
    key: 'add',
    type: 'shipping',
  },
  {
    name: 'Manufacturing Location',
    key: 'add',
    type: 'manufacturing',
  },
  {
    name: 'Business Office',
    key: 'add',
    type: 'business_office',
  },
]

export const shippingTerms = [
  {
    name: 'Any Shipping Terms',
    value: 'any',
  },
  {
    name: 'FCA',
    value: 'FCA',
  },
  {
    name: 'FOB',
    value: 'FOB ',
  },
  {
    name: 'Collect',
    value: 'Collect ',
  },
  {
    name: 'Prepaid',
    value: 'Prepaid ',
  },
]
export const Reason = [
  {
    name: 'Any Reason',
    value: 'any',
  },
  {
    name: 'Address Changes',
    value: 'Address Change',
  },
  {
    name: 'Duplicate',
    value: 'Duplicate',
  },
  {
    name: 'Government Change',
    value: 'Government Change',
  },
  {
    name: 'Not Validated',
    value: 'Not Validated',
  },
  {
    name: 'Small Changes',
    value: 'Small Change',
  },
]
