/***************************** Root Reducer ***********************************/
export const RESET_USER_DATA_ADD = 'RESET_USER_DATA_ADD'

/***************************** Common Reducer *********************************/
export const SET_LOADING = 'SET_LOADING'

export const SET_VISIBLE = 'SET_VISIBLE'

export const SET_ERROR = 'SET_ERROR'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'

export const RESET_COMMON = 'RESET_COMMON'

/********************************************************************************/

/****************************** API Data Reducer ********************************/
// Add location
export const FETCH_COUNTRY_LIST = 'FETCH_COUNTRY_LIST'

export const FETCH_CITY_STATE_BY_ZIPCODE = 'FETCH_CITY_STATE_BY_ZIPCODE'

export const FETCH_ADDRESS_FORMAT = 'FETCH_ADDRESS_FORMAT'

export const FETCH_STATES_LIST = 'FETCH_STATES_LIST'

export const FETCH_SUGGESTED_ADDRESS = 'FETCH_SUGGESTED_ADDRESS'

export const FETCH_EXIST_DUP_ADDRESS_AND_VALIDATE =
  'FETCH_EXIST_DUP_ADDRESS_AND_VALIDATE'

export const RESET_EXIST_DUP_ADDRESS_AND_VALIDATE =
  'RESET_EXIST_DUP_ADDRESS_AND_VALIDATE'

export const FETCH_LOCATION_TYPES = 'FETCH_LOCATION_TYPES'

export const FETCH_LOCATION_CAPABILITY = 'FETCH_LOCATION_CAPABILITY'

export const FETCH_LOCATION_TYPE_CAPABILITY_BY_ID =
  'FETCH_LOCATION_TYPE_CAPABILITY_BY_ID'

export const SAVE_ADD_DETAILS_SUCCESS = 'SAVE_ADD_DETAILS_SUCCESS'

export const FETCH_CARRIER_DETAILS = 'FETCH_CARRIER_DETAILS'

export const FETCH_TIMEZONE = 'FETCH_TIMEZONE'

export const FETCH_HOLIDAY_LIST = 'FETCH_HOLIDAY_LIST'

export const FETCH_TRANSIT_HOURS = 'FETCH_TRANSIT_HOURS'

export const FETCH_PORT_OF_EXPORT = 'FETCH_PORT_OF_EXPORT'

export const FETCH_LOCATION_CONTACTS = 'FETCH_LOCATION_CONTACTS'

export const FETCH_LOCATION_DETAILS = 'FETCH_LOCATION_DETAILS'

export const FETCH_SHIP_TERMS = 'FETCH_SHIP_TERMS'

export const FETCH_LOCATION_READY_CHECKLIST = 'FETCH_LOCATION_READY_CHECKLIST'

export const FETCH_SHIPPING_DETAILS = 'FETCH_SHIPPING_DETAILS'

export const FETCH_SIMILAR_LOCATIONS = 'FETCH_SIMILAR_LOCATIONS'

export const FETCH_REASON_FOR_EDIT = 'FETCH_REASON_FOR_EDIT'

export const SAVE_SHIPPING_DETAILS = 'SAVE_SHIPPING_DETAILS'

export const FETCH_IMPORT_DETAILS = 'FETCH_IMPORT_DETAILS'

export const FETCH_TRADE_PROGRAM_TYPES = 'FETCH_TRADE_PROGRAM_TYPES'

export const FETCH_REVIEW_ADDRESS = 'FETCH_REVIEW_ADDRESS'

export const FETCH_SECURITY_DETAILS = 'FETCH_SECURITY_DETAILS'

// Edit location
export const SAVE_LOCATION_DETAILS = 'SAVE_LOCATION_DETAILS'

export const SAVE_LOCATION_SUMMARY = 'SAVE_LOCATION_SUMMARY'

export const SAVE_SUPPLIER_DETAILS = 'SAVE_SUPPLIER_DETAILS'

export const RESET_API_DATA = 'RESET_API_DATA'

export const RESET_API_DATA_EXCEPT_COUNTRY = 'RESET_API_DATA_EXCEPT_COUNTRY'

/***********************************************************************************/

/****************************** User Data Reducer ******************************/
export const COUNTRY = 'COUNTRY'

export const ADDRESS = 'ADDRESS'

export const SAVE_DUPLICATE_SELECTED_LOC_ID = 'SAVE_DUPLICATE_SELECTED_LOC_ID'

export const SAVE_LOCATION_NAME = 'SAVE_LOCATION_NAME'

export const SAVE_LOCATION_TYPE = 'SAVE_LOCATION_TYPE'

export const SAVE_LOCATION_ID_SUPPLIER_TYPE_MAP =
  'SAVE_LOCATION_ID_SUPPLIER_TYPE_MAP'

export const SAVE_MANUFACTURING_ATTRIBUTES = 'SAVE_MANUFACTURING_ATTRIBUTES'

export const SAVE_LOCATION_CAPABILITY = 'SAVE_LOCATION_CAPABILITY'

export const SAVE_CARRIER_DETAILS = 'SAVE_CARRIER_DETAILS'

export const SAVE_ORDER_CUTOFF_TIME = 'SAVE_ORDER_CUTOFF_TIME'

export const SAVE_GENERAL_ORDER = 'SAVE_GENERAL_ORDER'

export const SAVE_HOUR_OPERATION = 'SAVE_HOUR_OPERATION'

export const SAVE_HOLIDAY_CLOSURE = 'SAVE_HOLIDAY_CLOSURE'

export const RESET_USER_DATA = 'RESET_USER_DATA'

export const SAVE_SHIP_TERMS = 'SAVE_SHIP_TERMS'

export const SAVE_TRANSIT_HOURS = 'SAVE_TRANSIT_HOURS'

export const SAVE_REASON_FOR_EDIT_ADDRESS = 'SAVE_REASON_FOR_EDIT_ADDRESS'

export const SAVE_LOCATION_ID = 'SAVE_LOCATION_ID'

export const SAVE_SELECTED_CONTACT = 'SAVE_SELECTED_CONTACT'

export const SAVE_POE_COUNTRY = 'SAVE_POE_COUNTRY'

export const SAVE_PORT_OF_EXPORT = 'SAVE_PORT_OF_EXPORT'

export const SAVE_UPDATED_SIM_LOC = 'SAVE_UPDATED_SIM_LOC'

export const ENABLE_EDIT_ADDRESS = 'ENABLE_EDIT_ADDRESS'

export const CHANGE_EDIT_SHIPPING_FLAGS = 'CHANGE_EDIT_SHIPPING_FLAGS'

export const HOUR_HOLIDAY_CLOSURE_EDITED = 'HOUR_HOLIDAY_CLOSURE_EDITED'

export const GEO_CODE_NOT_UPDATED = 'GEO_CODE_NOT_UPDATED'

export const REMOVE_DUPLICATE_LOC_ID_NAME = 'REMOVE_DUPLICATE_LOC_ID_NAME'

export const SHOW_EDIT_REVIEW_ADDRESS = 'SHOW_EDIT_REVIEW_ADDRESS'

export const VALIDATE_LOCATION_CONTACT = 'VALIDATE_LOCATION_CONTACT'
/***********************************************************************************/
