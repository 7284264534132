import { Button } from '@enterprise-ui/canvas-ui-react'
import { useNavigate } from 'react-router-dom'
import { useAuthAccessCheck } from '../../../../components/AuthAccessCheck/useAuthAccessCheck'
import { INTERNAL_REVIEW_PAGES } from '../../../../constants/componentAuthAccess'

const GridEditTemplate = (props) => {
  const history = useNavigate()
  const { shouldAccessBeGiven } = useAuthAccessCheck({
    allowedGroup: INTERNAL_REVIEW_PAGES.REVIEW_BUTTON,
  })

  const { params, text, button } = props

  const location_id = params.data?.location_id
  const supplier_id = params.data?.supplier_id

  const isRemovedReason =
    params.data?.review?.reason_for_edit === 'REMOVE' ||
    params.data?.review?.reason_for_edit === 'Remove'

  const url = `/location?p=${
    isRemovedReason ? 'remShip' : 'editShipInt'
  }&sid=${supplier_id}&id=${location_id}`

  return (
    <>
      <Button
        size="dense"
        type="primary"
        onClick={() => {
          history(url)
        }}
        aria-label={`${button} ${text} contact`}
        disabled={!shouldAccessBeGiven}
      >
        REVIEW
      </Button>
    </>
  )
}

export default GridEditTemplate
