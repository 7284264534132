import { SHOW_NOTIFICATION } from './actionType'
const initialState = {
  show: false,
  msg: '',
  isError: false,
}

export default function notifyReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      const { show, msg, isError, redirectPath, heading, duration } = action
      return {
        show: show,
        msg: msg || '',
        isError: isError || '',
        redirectPath: redirectPath || '',
        heading: heading || '',
        duration: duration || 5000,
      }
    }
    default:
      return state
  }
}
