const Cookie = {
  get: (name) => {
    let c = document.cookie.match(
      `(?:(?:^|.*; *)${name} *= *([^;]*).*$)|^.*$`
    )[1]
    if (c) return decodeURIComponent(c)
  },
  set: (name, value, opts = {}) => {
    /*If options contains days then we're configuring max-age*/
    if (opts.days) {
      opts['max-age'] = opts.days * 60 * 60 * 24
      delete opts.days
    }
    opts = Object.entries(opts).reduce(
      (accumulatedStr, [k, v]) => `${accumulatedStr}; ${k}=${v}`,
      ''
    )

    document.cookie = name + '=' + encodeURIComponent(value) + opts
  },
  delete: (name, opts) => Cookie.set(name, '', { 'max-age': -1, ...opts }),
  // path & domain must match cookie being deleted
}

export default Cookie
//Cookie.get('name')
//Cookie.set('name', 'value', {path: '/', days: 10})
//Cookie.delete('name', { path: '', domain: '.partnersonline.com' })
