import React from 'react'
import { scrollTo } from '../components/common/InternationalPhoneFormat/utills'
import Cookie from './useCookies'
import { Notification } from '../store/Notification/actionCreator'
import { dayjs } from '../components/common/Date/Dayjs'
import { sccRequiredCOuntries } from '../constants/common'

/**
 * Email validation based on regex
 * @param {String} emailAddress = email address string
 * @return {Boolean}
 */
export const isValidEmailAddress = (emailAddress) => {
  const pattern =
    /^([a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\u0001-\u0008\u000b\u000c\u000e-\u001f\u007f\u0021\u0023-\u005b\u005d-\u007e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\u0001-\u0009\u000b\u000c\u000d-\u007f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i // eslint-disable-line
  // const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(emailAddress)
}

export const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config)
  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items]
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [items, sortConfig])

  const requestSort = (key) => {
    let direction = 'ascending'
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }

  return { items: sortedItems, requestSort, sortConfig }
}

export const dateTimeDisplay = (date) => {
  const inpDate = date ? date : new Date()
  const formattedDate = inpDate.toLocaleString('en-GB', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    hour12: true,
    minute: '2-digit',
  })
  return formattedDate
}

export const handleFocusElem = (listItem) => {
  if (listItem) {
    let timer1 = setTimeout(() => listItem.focus(), 0)
    scrollTo(listItem, true)
    return () => {
      clearTimeout(timer1)
    }
  }
}

export const timeDifference = (time) => {
  try {
    const cstTime = dayjs.tz(time, 'CST')
    return dayjs(cstTime).fromNow(true)
  } catch (error) {
    //changes for firefox where dayjs is not working
    return time
  }
}

export const checkUser = (session) => {
  let memberOf,
    user,
    companyName,
    isUser = true
  if (session) {
    const business_category = session?.identity?.business_category
    memberOf = session?.identity?.memberof
    companyName = session?.identity?.company
    // isUser = business_category === '' || business_category === 'T'
    isUser = business_category === 'E'
  }
  user = { memberOf: memberOf, isUser: isUser, companyName: companyName }
  return user
}
export const isTarget = (url) => {
  url = url ? url : window.location.href
  // return /(localhost|^([^/]+:)?\/{2,3}[^/]+?(\.target\.com)(:|\/|$))/i.test(url)
  return /(^([^/]+:)?\/{2,3}[^/]+?(\.target\.com)(:|\/|$))/i.test(url)
}
export const isProd = (url) => {
  url = url ? url : window.location.href
  return /https?:\/\/(vm(\.(prod))?)\.(target|partnersonline)\.com/.test(url)
}
export const isSSOSessionActive = (session, dispatch) => {
  // Todo move to Auth controller
  const ssoSessionInfo = Cookie.get('SSOSESSIONINFO')
  if (ssoSessionInfo) {
    const sessionInfo = window.atob(ssoSessionInfo)
    const sessionInfoObj = JSON.parse(sessionInfo)
    const { lanId } = (session && session.userInfo) || {}
    const differentUser =
      lanId &&
      sessionInfoObj.user &&
      String(lanId) !== String(sessionInfoObj.user)
    if (!sessionInfoObj.user || !sessionInfoObj.login_status || differentUser) {
      dispatch(Notification(true, 'Session Expired or Company Changed!', true))
      setTimeout(() => {
        forceSignOut()
      }, 3000)
      return false
    }
  }
  return true
}
export const forceSignOut = () => {
  // Todo move to Auth controller
  localStorage.removeItem('id_token')
  localStorage.removeItem('access_token')
  localStorage.removeItem('X-USER-TOKEN')
  Cookie.delete('SSOSESSIONINFO')
  window.location.reload(true)
}
export const encodeUrl = (url) => {
  const encodeUrl = btoa(url)
  // const decodeUrl = atob(decodeURIComponent(encodeUrl))
  return encodeUrl
}

export const showSideNav = (isUser, location, isRegister) => {
  // const pageName = location.pathname
  // const supplierPages = ['/internal/supplier']
  // const restricted = supplierPages.includes(pageName) || isRegister
  // return !isUser && !restricted
  // const restricted =
  //   (pageName === '/' && !isUser) || pageName.match(/internal/gi)
  return !isUser
}

export const dateComparator = (date1, date2) => {
  const date1Number = dayjs(date1).unix()
  const date2Number = dayjs(date2).unix()
  if (date1Number === null && date2Number === null) {
    return 0
  }
  if (date1Number === null) {
    return -1
  }
  if (date2Number === null) {
    return 1
  }
  return date1Number - date2Number
}

export const checkSupplyChainRequired = (headquarterCountry) => {
  return sccRequiredCOuntries.includes(headquarterCountry) ? true : false
}
