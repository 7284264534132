import { dateComparator } from '../../../../utills/Common'
import { dayjs } from '../../../../components/common/Date/Dayjs'
import GridButtonTemplate from './GridButtonTemplate'
// import GridLinkTemplate from '../../../Supplier/Location/GridLinkTemplate'

let KEY_ENTER = 13

export const ColumnDefs = [
  {
    field: 'supplier_id',
    rowGroup: true,
    hide: true,
    valueGetter: (params) => {
      const { data } = params
      return `${data?.supplier_id} ${data?.supplier_name}`
    },
    cellRenderer: function (params) {
      return `${params?.data?.supplier_id} ${params?.data?.supplier_name}`
    },
  },
  {
    headerName: 'PENDING LOCS',
    field: 'location_status',
    minWidth: 120,
    valueGetter: (params) => {
      if (params.data) {
        return params.data?.address?.formatted_address
      }
    },
    cellClass: 'location_table_cell',
    cellRenderer: function (params) {
      const formattedAddress =
        params.data?.address?.formatted_address?.split('|') || []

      const hasChildren = params.node.allChildrenCount
      return (
        <div className="hc-fs-caption location_table_address">
          {hasChildren ? (
            <p className="hc-mt-sm">{hasChildren}</p>
          ) : (
            <>
              <p>{formattedAddress[0]}</p>
              <p>{formattedAddress[1]}</p>
            </>
          )}
        </div>
      )
    },
  },
  {
    headerName: 'REASON',
    field: 'reason_for_edit',
    minWidth: 120,
    valueGetter: (params) => {
      const { data } = params
      let resp = ''
      if (data?.review) {
        const { review } = data
        resp = review.reason_for_edit
          ? review.reason_for_edit
          : review.update_type
      }

      const splitReason = resp?.split(',')

      if (splitReason?.length > 1) {
        return 'Multiple'
      }

      return resp
    },
  },
  {
    headerName: 'VOSs',
    field: 'order_spec',
    maxWidth: 120,
    getQuickFilterText: () => '',
    cellRenderer: (params) => {
      return groupValues(params, 'order_spec')
    },
    suppressKeyboardEvent: (params) => {
      let { event } = params
      return event.which === KEY_ENTER
    },
  },
  {
    headerName: 'TERMS',
    field: 'ship_terms',
    minWidth: 150,
    valueGetter: (params) => {
      const { data = {} } = params
      const { ship_terms } = data

      const terms = {}

      if (ship_terms?.length) {
        ship_terms.forEach((term) => {
          const termName = term?.ship_term_name

          if (termName) {
            terms[termName] = termName
          }
        })
      }

      const visibleTerms = Object.keys(terms)?.length
        ? Object.keys(terms).join(', ')
        : ''

      return visibleTerms
    },
  },
  {
    headerName: 'ADDED',
    field: 'c_date',
    filterParams: {
      comparator: dateComparator,
    },
    comparator: dateComparator,
    valueGetter: function (params) {
      const { data } = params
      return dayjs(data?.c_date).format('MMMM D')
    },
    cellRenderer: (params) => {
      const { data } = params
      return data?.c_date ? dayjs(data?.c_date).format('MMMM D') : ''
    },
  },
  {
    headerName: ' ',
    floatingFilter: false,
    flex: 0,
    resizable: false,
    sortable: false,
    filter: false,
    suppressColumnsToolPanel: true,
    width: 120,
    minWidth: 120,
    cellStyle: { 'text-align': 'center', overflow: 'visible' },
    cellRenderer: (params) => {
      const text = params.data?.supplier_id
      return !params.node.allChildrenCount ? (
        <GridButtonTemplate params={params} button="Review" text={text} />
      ) : null
    },
    suppressKeyboardEvent: (params) => {
      // Open DropDown
      let { event } = params
      return event.which === KEY_ENTER
    },
  },
]

const groupValues = (params, key) => {
  const hasChildren = params.node.allChildrenCount
  if (hasChildren) {
    let rowData = []
    params.node.allLeafChildren.filter((value) => rowData.push(value.data[key]))
    const checkSame = [...new Set(rowData)].length === 1
    return checkSame ? rowData[0] : 'Multiple'
  } else {
    if (key === 'order_spec') {
      const data = params.data
      const cnt = data?.order_spec?.count ? data?.order_spec?.count : ''
      let html = ''
      if (cnt) {
        html = <span className="hc-fs-caption hc-ta-center">{cnt}</span>
      }
      return html
    } else {
      return params.data[key]
    }
  }
}
