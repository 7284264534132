import { createRef, useEffect } from 'react'
import {
  SideNav as SideNavComponent,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { siteNavList } from './SideNavList'
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import { useAnalytics } from '@praxis/component-analytics'
import { handleFocusElem } from './../../../utills/Common'
import { checkUser } from '../../../utills/Common'
import { useHeader } from '../Header/useHeader'
// import { useFirefly } from '../../../utills/useAnalytics'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import EnterpriseIcon, {
  PlusIcon,
  GroupIcon,
  WorkIcon,
  ImageIcon,
  TeacherIcon,
  DivisionShoesIcon,
  DivisionMensIcon,
  DivisionMeatFrozenDairyIcon,
  CheckIcon,
  TruckIcon,
  PencilIcon,
  PaperAirplaneIcon,
  StarIcon,
  StoreMapIcon,
  LogoutIcon,
} from '@enterprise-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { resetLocationSearchOption } from '../../../store/Internal/actionCreator'
import { useDispatch } from 'react-redux'
const getIcons = (icon) => {
  switch (icon) {
    case 'GroupIcon':
      return GroupIcon
    case 'DivisionShoesIcon':
      return DivisionShoesIcon
    case 'DivisionMensIcon':
      return DivisionMensIcon
    case 'DivisionMeatFrozenDairyIcon':
      return DivisionMeatFrozenDairyIcon
    case 'CheckIcon':
      return CheckIcon
    case 'TruckIcon':
      return TruckIcon
    case 'PencilIcon':
      return PencilIcon
    case 'PaperAirplaneIcon':
      return PaperAirplaneIcon
    case 'StarIcon':
      return StarIcon
    case 'StoreMapIcon':
      return StoreMapIcon
    case 'ImageIcon':
      return ImageIcon
    case 'WorkIcon':
      return WorkIcon
    case 'TeacherIcon':
      return TeacherIcon
    default:
      return PlusIcon
  }
}
export const SideNavLink = (props) => {
  let { data, location, handleClick, isChild } = props
  return (
    <SideNavComponent.NavigationItem
      // as={NavLink}
      to={data.href}
      className={
        location.pathname === data.href && !data.tab ? 'isSelected' : ''
      }
      onClick={(event) => handleClick(event, data)}
    >
      {!isChild && data?.iconLeft && (
        <EnterpriseIcon icon={getIcons(data?.iconLeft)} />
      )}
      {data.label}
      {!isChild && data.icon && (
        <FontAwesomeIcon icon={faExternalLinkAlt} className="mla" />
      )}
    </SideNavComponent.NavigationItem>
  )
}

export const SideNavigation = ({
  isSideNavOpen,
  onRequestClose,
  auth,
  isDarkMode,
  setIsDarkMode,
}) => {
  let location = useLocation()
  let history = useNavigate()
  const { logOutRedirect } = useHeader(auth)
  // const { trackClick } = useAnalytics()
  let year = new Date().getFullYear()
  const sideNavLink = createRef()

  const { session } = auth || {}
  //const { firstName, lastName } = (session && session.userInfo) || {}
  const { memberOf } = (session && session.userInfo) || {}
  const user = checkUser(session)
  const dispatch = useDispatch()

  const sideNavList = siteNavList(memberOf, user?.isUser)
  const handleClick = (event, data) => {
    // commenting event,as canvas7.3.1 doesn't support event for handleclick
    // event.preventDefault()
    dispatch(resetLocationSearchOption())
    const { href, tab } = data
    if (href) {
      // const { target } = event
      // trackClick(target.textContent)
      if (/^https?:\/\//i.test(href)) {
        window.open(href, '_blank')
      } else {
        history(href, {
          pathname: href,
          state: { tab: tab },
        })
      }
    }
  }
  const onBlurSideNav = (event) => {
    const { currentTarget, target } = event
    // Hide the left nav on tab out
    setTimeout(() => {
      if (
        !currentTarget.contains(document.activeElement) &&
        (currentTarget.lastElementChild?.firstChild === target ||
          currentTarget.children[1]?.firstElementChild === target)
      ) {
        onRequestClose()
      }
    }, 0)
  }

  useEffect(() => {
    if (isSideNavOpen) {
      let link = sideNavLink.current.nextElementSibling.children[0]
      handleFocusElem(link)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSideNavOpen])
  return (
    <SideNavComponent
      //isVisible={isSideNavOpen}
      canMinimize={true}
      onRequestClose={onRequestClose}
      // className={`${isSideNavOpen ? 'show-content' : 'hide-content'}`}
      // suppressAutoOpen
      isMinimized={false}
      onKeyDown={(event) => onBlurSideNav(event)}
      tabIndex="1"
      onMinimizeChange={onRequestClose}
      hasOverlay={false}
      style={{ zIndex: 9 }}
    >
      <span ref={sideNavLink} className="hide-content">
        VM Side Navigation
      </span>
      <SideNavComponent.Navigation>
        {sideNavList.map((component) => {
          let hasChildren = component.children && component.children.length > 0
          if (hasChildren) {
            return (
              <SideNavComponent.NavigationGroup
                key={`parent_${component.label}`}
              >
                <SideNavComponent.NavigationItem>
                  {component?.iconLeft && (
                    <EnterpriseIcon icon={getIcons(component?.iconLeft)} />
                  )}
                  {component.label}
                </SideNavComponent.NavigationItem>
                <SideNavComponent.NavigationGroupContent>
                  {component.children.map((childComponent) => {
                    return (
                      <SideNavLink
                        key={`child_${component.label}-${childComponent.label}`}
                        data={childComponent}
                        location={location}
                        handleClick={(event) =>
                          handleClick(event, childComponent)
                        }
                        isChild={true}
                      />
                    )
                  })}
                </SideNavComponent.NavigationGroupContent>
              </SideNavComponent.NavigationGroup>
            )
          } else {
            return (
              <SideNavLink
                key={`single_${component.label}`}
                data={component}
                location={location}
                handleClick={(event) => handleClick(event, component)}
              />
            )
          }
        })}
      </SideNavComponent.Navigation>
      <SideNavComponent.Footer>
        <SideNavComponent.NavigationItem>
          <Input.Toggle
            onClick={(event) => setIsDarkMode(!isDarkMode)}
            id="theme_toggle"
            label="Dark Mode"
            value={isDarkMode}
          />
        </SideNavComponent.NavigationItem>
        <SideNavComponent.NavigationItem onClick={(e) => logOutRedirect(e)}>
          <EnterpriseIcon icon={LogoutIcon} /> Logout
        </SideNavComponent.NavigationItem>
        <Link to={`#`} id="footer" className="hc-fs-caption ">
          © {year} Target.
        </Link>
      </SideNavComponent.Footer>
    </SideNavComponent>
  )
}

export default SideNavigation
