/* Actions */
import * as Actions from './actionType'

/* Constants */
import { PAGE_TYPE } from '../../constants/pageConstants'

/* Initial State */
export const contactState = {
  currentPage: PAGE_TYPE.INIT_ADD,
}

const checkIsFormTouched = (state) => {
  // When Contact is Edit, then only we save this flag while saving fetched api data. So that means, till that time, nothing has been edited. And now if user edit anything, then we in reducer itself, we have check where it will made to false.
  if (state.isFormNotTouched) {
    return {
      isFormNotTouched: false,
    }
  }
}

const contact = (state = contactState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case Actions.CHANGE_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: payload,
      }
    }

    case Actions.PURGE_AND_RESET_DATA: {
      return contactState
    }

    case Actions.FETCH_CONTACT_DATA: {
      return {
        ...state,
        apiData: payload,
      }
    }

    case Actions.SAVE_USER_INFO: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...payload,
        },
        ...checkIsFormTouched(state),
      }
    }

    case Actions.NORMALIZE_SUPPLIER_DATA: {
      return {
        ...state,
        ...payload,
        ...checkIsFormTouched(state),
      }
    }

    case Actions.UPDATE_SUPPLIER_TYPE: {
      return {
        ...state,
        supplierTypeData: payload,
        ...checkIsFormTouched(state),
      }
    }

    case Actions.UPDATE_DEPARTMENT: {
      return {
        ...state,
        departmentData: payload,
        ...checkIsFormTouched(state),
      }
    }

    case Actions.SET_POL_SYSTEM_ACCESS: {
      return {
        ...state,
        system_access: payload,
        ...checkIsFormTouched(state),
      }
    }

    case Actions.SET_COMPANY_ADMIN: {
      return {
        ...state,
        admin: payload,
        ...checkIsFormTouched(state),
      }
    }

    case Actions.SET_NOTIFY_USER_LABEL: {
      return {
        ...state,
        notify_user: payload,
        ...checkIsFormTouched(state),
      }
    }

    case Actions.UPDATE_APPS_AND_REPORT: {
      return {
        ...state,
        apps_and_report: payload,
        ...checkIsFormTouched(state),
      }
    }

    case Actions.UPDATE_RESPONSIBILITY: {
      return {
        ...state,
        responsibilities: payload,
        ...checkIsFormTouched(state),
      }
    }

    case Actions.SAVE_POST_API_DATA: {
      return {
        ...state,
        postApiData: payload,
      }
    }

    default:
      return state
  }
}

export default contact
