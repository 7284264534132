import { SHOW_NOTIFICATION } from './actionType'

export function Notification(
  show,
  msg,
  isError,
  redirectPath,
  heading,
  duration
) {
  return {
    type: SHOW_NOTIFICATION,
    show: show,
    isError: isError,
    msg: msg,
    heading: heading,
    redirectPath: redirectPath ? redirectPath : '',
    duration: duration ? duration : 5000,
  }
}
