import * as ACTIONS from './actionType'

const initialState = {
  tree: [],
  loading: false,
  error: null,
}

const companyTree = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.TYPE.FETCH_COMPANY_TREE_START:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case ACTIONS.TYPE.FETCH_COMPANY_TREE_SUCCESS:
      return {
        ...state,
        loading: false,
        tree: action.tree,
      }
    case ACTIONS.TYPE.FETCH_COMPANY_TREE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload?.error?.error,
        tree: [],
      }
    default:
      return state
  }
}
export default companyTree
