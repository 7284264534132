export const TYPE = {
  TARGET_SETUP_INFO: 'TARGET_SETUP_INFO',
  TARGET_SETUP_INIT: 'TARGET_SETUP_INIT',
  TARGET_SETUP_FAIL: 'TARGET_SETUP_FAIL',
  TARGET_SETUP_RESET: 'TARGET_SETUP_RESET',
  SUPPLIER_LOCATION_INFO: 'SUPPLIER_LOCATION_INFO',
  SUPPLIER_LOCATION_INIT: 'SUPPLIER_LOCATION_INIT',
  SUPPLIER_LOCATION_FAIL: 'SUPPLIER_LOCATION_FAIL',
  SUPPLIER_CONTACT_INFO: 'SUPPLIER_CONTACT_INFO',
  SUPPLIER_CONTACT_RESET: 'SUPPLIER_CONTACT_RESET',
  SUPPLIER_CONTACT_INIT: 'SUPPLIER_CONTACT_INIT',
  SUPPLIER_CONTACT_FAIL: 'SUPPLIER_CONTACT_FAIL',
  SUPPLIER_CONTACT_REVIEW_INFO: 'SUPPLIER_CONTACT_REVIEW_INFO',
  SUPPLIER_CONTACT_REVIEW_INIT: 'SUPPLIER_CONTACT_REVIEW_INIT',
  SUPPLIER_CONTACT_REVIEW_FAIL: 'SUPPLIER_CONTACT_REVIEW_FAIL',
  SUPPLIER_RELATIONSHIP_INFO: 'SUPPLIER_RELATIONSHIP_INFO',
  SUPPLIER_RELATIONSHIP_INIT: 'SUPPLIER_RELATIONSHIP_INIT',
  SUPPLIER_RELATIONSHIP_FAIL: 'SUPPLIER_RELATIONSHIP_FAIL',
  SUPPLIER_RELATIONSHIP_RESET: 'SUPPLIER_RELATIONSHIP_RESET',
  SUPPLIER_RESET: 'SUPPLIER_RESET',
  SUPPLIER_LOCATION_RESET: 'SUPPLIER_LOCATION_RESET',
  FETCH_ASSIGN_ROLES: 'FETCH_ASSIGN_ROLES',
  UPDATE_ASSIGN_ROLES: 'UPDATE_ASSIGN_ROLES',
  FETCH_SUPPLIER_DOCUMENT: 'FETCH_SUPPLIER_DOCUMENT',
  RESET_SUPPLIER_DOCUMENT: 'RESET_SUPPLIER_DOCUMENT',
  FETCH_DEPARTMENTS_WITH_NO_SHIPPING_LOCATION_INIT:
    'FETCH_DEPARTMENTS_WITH_NO_SHIPPING_LOCATION_INIT',
  FETCH_DEPARTMENTS_WITH_NO_SHIPPING_LOCATION_INFO:
    'FETCH_DEPARTMENTS_WITH_NO_SHIPPING_LOCATION_INFO',
  FETCH_DEPARTMENTS_WITH_NO_SHIPPING_LOCATION_FAIL:
    'FETCH_DEPARTMENTS_WITH_NO_SHIPPING_LOCATION_FAIL',
}
