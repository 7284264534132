import GridLinkTemplate from '../../GridLinkTemplate'

export const GridOptions = {
  defaultColDef: {
    filter: true,
    resizable: true,
    editable: false,
    sortable: true,
    flex: 1,
    headerClass: 'vm-grid-header',
    cellClass: ['vmm_flex_center'],
  },
  //enableCellTextSelection: true,
  sortingOrder: ['desc', 'asc'],
  rowData: [],
  headerHeight: 50,
  rowHeight: 50,
  //suppressContextMenu: true,
  groupHideOpenParents: false,
  groupRemoveSingleChildren: true,
  groupDefaultExpanded: 0, // -1/ 0 Expand collapse
  // suppressAggFuncInHeader: true,
  // rowSelection: 'multiple',
  // groupSelectsChildren: true,
  // groupUseEntireRow:true,
  autoGroupColumnDef: {
    // headerCheckboxSelection: true,
    // checkboxSelection: true,
    headerName: 'SUPPLIER / DEPT',
    width: 200,
    cellClass: (params) => {
      // if (params.node.parent.allChildrenCount === 1) {
      //   return 'remove-expand'
      // } else {
      //   return null
      // }
    },
    checkboxSelection: (params) => {
      return !params.node.allChildrenCount
    },
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: (params) => {
        let row
        if (params.node.allChildrenCount) {
          row = params.node.allLeafChildren[0].data
        } else {
          row = params.data
        }
        // const comp = row.supplier_name
        const dept = row?.departments
          .map((item) => {
            return item.id
          })
          .join(', ')
        const getSupplierId = Number(params?.value?.split(' ')[0])
        return (
          // <p className="vm-text-ellipsis hc-mb-none" style={{ width: '98%' }}>
          //   {params.value}, {comp} <br /> DEPT {dept}
          // </p>
          <>
            <GridLinkTemplate
              url={
                getSupplierId
                  ? `/supplier-view/${getSupplierId}`
                  : `/supplier-view/${params?.data?.supplier_id}`
              }
              params={
                params.node.allChildrenCount
                  ? params.value
                  : params?.node?.parent?.key
              }
            />
            <br /> DEPT {dept}
          </>
        )
      },
    },
  },
  overlayLoadingTemplate: `<span className="ag-overlay-loading-center">Please wait while your rows are loading</span>`,
  overlayNoRowsTemplate: `<span className="ag-overlay-loading-center">Location not found</span>`,
}
