import axios from 'axios'
import { Notification } from '../../store/Notification/actionCreator'
import { isSSOSessionActive } from '../../utills/Common'
import { store } from '../../App'
import apiConfig from '../../config/apiConfig'

const requestInterceptor = async (dispatch, session) => {
  axios.interceptors.request.use(
    (config) => {
      if (config && config.headers) {
        if (session && isSSOSessionActive(session, dispatch)) {
          // const userIdToken = session.identityToken // fix goProxy
          const userIdToken = session.accessToken
          // const userEmail = session.userInfo.email
          if (userIdToken !== null) {
            const supplierToken = store?.getState()?.supplierInfo?.token

            config.headers['Authorization'] = `Bearer ${userIdToken}`
            // config.headers['content-type'] = `application/json` // Todo post release uncomment and check
            config.headers['x-api-key'] = apiConfig.key.api

            if (supplierToken) {
              config.headers['X-USER-TOKEN'] = supplierToken
            }
            config.headers['x-business-category'] =
              session?.identity?.business_category || 'I'
          }
        }
        return config
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  /***/
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error = {}) => {
      if (error.response) {
        let code, errorMsg
        // let errorType, errorMsg
        if (error.response) {
          if (error.response?.data?.meta_info?.error) {
            code = error.response?.data.meta_info.error.status
            errorMsg = error.response?.data.meta_info.error.message
          } else {
            code = error.response.status
            errorMsg = error.response.data.message
          }
        }
        // const errorText = `${error.response.status} ${errorType}: ${errorMsg}`
        if (code === 301 || code === 302) {
          dispatch(
            Notification(
              true,
              'Please contact support if this issue persists.',
              true
            )
          )
          return Promise.reject(error)
          // window.location.reload(true)
          //301 Moved Permanently // 301 Moved temporarily.
        } else if (code === 401) {
          dispatch(
            Notification(true, 'You’re not authorized', true, '/unauthorized')
          )
        } else if (code === 403) {
          // Unauthorized to handle
          dispatch(
            Notification(
              true,
              'You’re not authorized to do that.',
              true,
              '/forbidden'
            )
          )
        } else if (code === 404) {
          dispatch(Notification(true, 'Invalid request URL', true, '/NotFound'))
          return Promise.reject(error)
        } else if (code >= 400 && code < 500) {
          dispatch(Notification(true, errorMsg, true))
        } else if (code >= 500) {
          dispatch(
            Notification(
              true,
              'Failed to fetch the resource. Please try again later.',
              true
            )
          )
          //  return errorText
        }
        // Do something with response error
        return Promise.reject(error)
      } else {
        if (error && error.message === 'Network Error') {
          dispatch(Notification(true, 'Network Error', true))
          // window.location.reload(true) // When 302 error occurs
        }
        if (!error.status) {
          dispatch(
            Notification(true, 'Request failed due to network error.', true)
          )
          //Request failed due to network error.
        }
      }
    }
  )
}

export default requestInterceptor
